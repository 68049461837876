import {
  useQuery,
  type QueryObserverSuccessResult,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query"
import { DEFAULT_SUSPENSE } from "./constants"
import type { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getIsRunningQueryAll } from "./useQueryAll"
import { getArgs } from "./utils/getArgs"
import { isPromise } from "./utils/isPromise"

type BaseInitialData<Fn extends FnBase> =
  | FnReturn<Fn>
  | (() => FnReturn<Fn>)
  | undefined

type Options<
  Fn extends FnBase,
  TData,
  Suspense,
  Enabled,
  InitialData,
  ReturnOnlyData
> = Omit<
  UseQueryOptions<FnReturn<Fn>, unknown, TData>,
  "queryFn" | "queryKey" | "suspense" | "enabled" | "initialData"
> & {
  suspense?: Suspense
  enabled?: Enabled
  initialData?: InitialData
  returnOnlyData?: ReturnOnlyData
}

type OnlySuccessType<TData> = QueryObserverSuccessResult<TData>

type AllTypes<TData> = UseQueryResult<TData>

type IsSuspenseType<TData, Suspense> = Suspense extends true
  ? OnlySuccessType<TData>
  : AllTypes<TData>

type IsEnabledType<TData, Suspense, Enabled> = Enabled extends true
  ? IsSuspenseType<TData, Suspense>
  : Enabled extends boolean
  ? AllTypes<TData>
  : IsSuspenseType<TData, Suspense>

type UseQueryReturn<TData, Suspense, Enabled, InitialData> =
  InitialData extends undefined
    ? IsEnabledType<TData, Suspense, Enabled>
    : OnlySuccessType<TData>

type Return<TData, Suspense, Enabled, InitialData, ReturnOnlyData> =
  ReturnOnlyData extends true
    ? UseQueryReturn<TData, Suspense, Enabled, InitialData>["data"]
    : UseQueryReturn<TData, Suspense, Enabled, InitialData>

type NoArgs<Fn extends FnBase> = {
  <
    TData = FnReturn<Fn>,
    Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
    Enabled extends boolean | undefined = undefined,
    InitialData extends BaseInitialData<Fn> = undefined,
    ReturnOnlyData extends boolean | undefined = true
  >(
    args?: FnParams<Fn>,
    options?: Options<Fn, TData, Suspense, Enabled, InitialData, ReturnOnlyData>
  ): Return<TData, Suspense, Enabled, InitialData, ReturnOnlyData>

  <
    TData = FnReturn<Fn>,
    Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
    Enabled extends boolean | undefined = undefined,
    InitialData extends BaseInitialData<Fn> = undefined,
    ReturnOnlyData extends boolean | undefined = true
  >(
    options?: Options<Fn, TData, Suspense, Enabled, InitialData, ReturnOnlyData>
  ): Return<TData, Suspense, Enabled, InitialData, ReturnOnlyData>
}

type AnyArgs<Fn extends FnBase> = {
  <
    TData = FnReturn<Fn>,
    Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
    Enabled extends boolean | undefined = undefined,
    InitialData extends BaseInitialData<Fn> = undefined,
    ReturnOnlyData extends boolean | undefined = true
  >(
    args: FnParams<Fn>,
    options?: Options<Fn, TData, Suspense, Enabled, InitialData, ReturnOnlyData>
  ): Return<TData, Suspense, Enabled, InitialData, ReturnOnlyData>
}

export type UseQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? NoArgs<Fn>
  : AnyArgs<Fn>

export const getUseQuery = (fn: FnBase, path: string[]): UseQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const [options] = rest
    const key = [...path, ...args]

    const suspense = options?.suspense ?? DEFAULT_SUSPENSE

    const shouldPrefetch = !suspense && (options?.enabled ?? true)
    if (shouldPrefetch) {
      // React won't trigger useEffect until components in the tree are done suspending.
      // This can introduce unnecessary delays before the request starts.
      // Therefore we use prefetch to start the request early.
      //
      // When there is an error, this causes an infinite api loop.
      // TODO: Think about it
      // queryClient.prefetchQuery(key, () => fn(...args))
    }

    const withDefaults = { ...options, suspense } as any

    try {
      const result = useQuery(key, () => fn(...args), withDefaults)

      const returnOnlyData = options?.returnOnlyData ?? true
      return returnOnlyData ? result.data : result
    } catch (error) {
      if (isPromise(error) && getIsRunningQueryAll()) {
        return error
      }

      throw error
    }
  }
}
