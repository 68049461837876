import { useMatch } from "@tanstack/react-location"
import { v3 } from "backoffice-api"
import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { toggleLike } from "src/bonzai/toggleLike"
import { getElapsedTime } from "src/dataUtilities/getElapsedTime"
import { usePickText } from "src/i18n/usePickText"
import { NewsItemView } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const NewsItemViewLoader = () => {
  useScrollToTopOnMount()

  return (
    <NewsItemView>
      <QueryBoundary fallback={<NewsItemView.Skeleton />}>
        <Load />
      </QueryBoundary>
      <NewsItemView.CloseButton closeRoute="/news" />
    </NewsItemView>
  )
}

const Load = () => {
  const { i18n, t } = useTranslation()

  const newsPostId = useMatch().params.newsPostId!
  const { data: post } = v3.getNewsPost.useQuery([newsPostId])
  const pickText = usePickText()

  return (
    <>
      <NewsItemView.Content
        image={post.image?.file_url ?? ""}
        title={pickText(post.headline)}
        description={pickText(post.body)}
      />
      <NewsItemView.Bottom
        numberOfLikes={post.likes_count}
        displayDate={getElapsedTime({
          date: post.updated_at,
          locale: i18n.language,
        })}
        likeText={post.is_liked ? t("news.LIKED") : t("news.LIKE")}
        isLiked={post.is_liked}
        onLike={() => toggleLike(post)}
      />
    </>
  )
}
