import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import playIcon from "assets/icons/playIcon.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { Card, ProgressBar } from "materia"
import type { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./JourneyCard.module.scss"

type Props = {
  activityProgress: number
  activityProgressMax: number
  image: string
  link: FiestaLinkProps
  productType: string
  stars: number
  starsMax: number
  title: string

  isActivityProgressVisible: boolean
  isCertified: boolean
  isFastProduct: boolean
  isImageVisible: boolean
  isInProgress: boolean
  isMandatory: boolean
  isLocked: boolean
  isProductTypeVisible: boolean
  isStarsVisible: boolean
  isAttendingVisible: boolean

  inProgressText: string
  mandatoryText: string
  attendingText: string
}

export const JourneyCard = (props: Props) => {
  const {
    link,
    isMandatory,
    isCertified,
    isStarsVisible,
    isProductTypeVisible,
    isImageVisible,
    isActivityProgressVisible,
    isLocked,
    isAttendingVisible,
  } = props

  return (
    <FiestaLink
      {...link}
      className={modifiers(s, "card", {
        isMandatory,
        isLocked,
        isActivityProgressVisible,
      })}
    >
      {isImageVisible && <Image {...props} />}
      <Bottom>
        <RowOne>
          <Title {...props} />
          {isCertified && <Certified />}
        </RowOne>
        <RowTwo>
          {isStarsVisible && <Stars {...props} />}
          {isProductTypeVisible && <ProductType {...props} />}
          {isAttendingVisible && <Attending {...props} />}
        </RowTwo>
        {isActivityProgressVisible && <ActivityProgress {...props} />}
      </Bottom>
      {isMandatory && <Mandatory {...props} />}
    </FiestaLink>
  )
}

const Bottom = ({ children }: PropsWithChildren) => (
  <div className={s.bottom}>{children}</div>
)

const RowOne = ({ children }: PropsWithChildren) => (
  <div className={s.rowOne}>{children}</div>
)

const RowTwo = ({ children }: PropsWithChildren) => (
  <div className={s.rowTwo}>{children}</div>
)

type TitleProps = {
  title: string
  isActivityProgressVisible: boolean
}
const Title = ({ title, isActivityProgressVisible }: TitleProps) => (
  <div className={modifiers(s, "title", { isActivityProgressVisible })}>
    {title}
  </div>
)

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Image = ({ image }: Props) => (
  <div className={s.image}>
    <FiestaImage
      className={s.image__image}
      src={image}
      alt=""
      srcSet={[30, 60, 90, 120]}
      sizes="(width = small) 30px, 60px"
    />
    <img className={s.image__play} src={playIcon} alt="" />
  </div>
)

const Stars = ({ starsMax, stars }: Props) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}

const ProductType = ({
  productType,
  isInProgress,
  inProgressText,
  isFastProduct,
}: Props) => (
  <div className={modifiers(s, "productType", { isFastProduct })}>
    {productType} {isInProgress && ` (${inProgressText})`}
  </div>
)

const Attending = ({ attendingText }: Props) => (
  <div className={s.attending}>{attendingText}</div>
)

const ActivityProgress = ({
  activityProgress,
  activityProgressMax,
  isCertified,
  isActivityProgressVisible,
}: Props) => (
  <div
    className={modifiers(s, "progress", {
      isActivityProgressVisible,
    })}
  >
    <ProgressBar
      value={activityProgress ?? 0}
      maxValue={activityProgressMax ?? 100}
      color={isCertified ? "green-30" : "blue-40"}
    />
  </div>
)

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)

const Skeleton = () => (
  <Card>
    <div className={s.skeleton}>
      <div className={s.skeleton__bottomType} />
      <div className={s.skeleton__bottomTitle} />
    </div>
  </Card>
)

JourneyCard.Skeleton = Skeleton
