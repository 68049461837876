import { v3 } from "backoffice-api"
import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { toggleLike } from "src/bonzai/toggleLike"
import { getElapsedTime } from "src/dataUtilities/getElapsedTime"
import { usePickText } from "src/i18n/usePickText"
import { NewsCard, NewsView } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const NewsViewLoader = () => {
  useScrollToTopOnMount()

  return (
    <QueryBoundary fallback={<NewsView.Skeleton />}>
      <NewsView>
        <Load />
      </NewsView>
    </QueryBoundary>
  )
}

const Load = () => {
  const { t, i18n } = useTranslation()
  const { data } = v3.getNewsPosts.useQuery()
  const pickText = usePickText()

  const isEmpty = data.length === 0

  if (isEmpty) {
    return <NewsView.Empty description={t("notFound.NOTFOUND_NEWS")} />
  }

  const newsCards = data.map((post) => (
    <NewsCard
      key={post.id}
      link={{ to: `/news/post/${post.id}` }}
      displayDate={getElapsedTime({
        date: post.updated_at,
        locale: i18n.language,
      })}
      description={pickText(post.body)}
      image={post.image?.file_url ?? ""}
      numberOfLikes={post.likes_count}
      title={pickText(post.headline)}
      readMoreText={t("news.READ_MORE")}
      likeText={post.is_liked ? t("news.LIKED") : t("news.LIKE")}
      isLiked={post.is_liked}
      onClick={() => toggleLike(post)}
    />
  ))

  return <>{newsCards}</>
}
