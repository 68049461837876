import { leaderboard } from "leaderboard-api"
import { useWindowWidth } from "materia"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { useFormatUser } from "src/bonzai/useFormatUser"
import { formatNumber } from "src/dataUtilities/formatNumber"
import { ProductModalLeaderboard } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type ProductModalLeaderboardLoaderProps = {
  productId: number
}
export const ProductModalLeaderboardLoader = (
  props: ProductModalLeaderboardLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalLeaderboard.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalLeaderboardLoaderProps) => {
  const { i18n } = useTranslation()
  const formatUser = useFormatUser()

  const highScores = useData(productId)
  if (highScores === undefined) return null

  const { entries, my_entry } = highScores

  const podium = entries
    .slice(0, 3)
    .map((entry, index) => (
      <ProductModalLeaderboard.PodiumItem
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(i18n.language, entry.value)}
      />
    ))

  const entryElements = entries
    .slice(3)
    .map((entry, index) => (
      <ProductModalLeaderboard.Entry
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(i18n.language, entry.value)}
      />
    ))

  return (
    <ProductModalLeaderboard>
      <ProductModalLeaderboard.Podium>{podium}</ProductModalLeaderboard.Podium>
      <ProductModalLeaderboard.Entries>
        {entryElements}
      </ProductModalLeaderboard.Entries>
      <ProductModalLeaderboard.MyEntry>
        <ProductModalLeaderboard.Entry
          image={my_entry.user.image}
          name={formatUser(my_entry.user)}
          rank={my_entry.rank}
          score={formatNumber(i18n.language, my_entry.value)}
        />
      </ProductModalLeaderboard.MyEntry>
    </ProductModalLeaderboard>
  )
}

const useData = (product_id: number) => {
  const scope = custom.getLeaderboardScopes.useQuery({
    select: (res) => res[0]?.value,
  })

  return leaderboard.getHighScores.useQuery(
    [{ product_id, scope: scope!, length: 50 }],
    { enabled: scope !== undefined }
  )
}
