import {
  useInfiniteQuery,
  type InfiniteData,
  type InfiniteQueryObserverSuccessResult,
  type QueryFunction,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
} from "@tanstack/react-query"
import { merge } from "lodash-es"
import { DEFAULT_SUSPENSE } from "./constants"
import type { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type PartialArray<T extends readonly any[]> = {
  readonly [P in keyof T]?: T[P] extends Record<any, any> ? Partial<T[P]> : T[P]
}

type BaseInitialData<Fn extends FnBase> =
  | InfiniteData<FnReturn<Fn>>
  | (() => InfiniteData<FnReturn<Fn>>)
  | undefined

type Options<Fn extends FnBase, TData, Suspense, Enabled, InitialData> = Omit<
  UseInfiniteQueryOptions<FnReturn<Fn>, unknown, TData>,
  | "queryFn"
  | "queryKey"
  | "suspense"
  | "select"
  | "enabled"
  | "initialData"
  | "getNextPageParam"
  | "getPreviousPageParam"
> & {
  suspense?: Suspense
  enabled?: Enabled
  initialData?: InitialData
  getNextPageParam?: (
    lastPage: FnReturn<Fn>,
    allPages: FnReturn<Fn>[]
  ) => PartialArray<FnParams<Fn>> | undefined
  getPreviousPageParam?: (
    firstPage: FnReturn<Fn>,
    allPages: FnReturn<Fn>[]
  ) => PartialArray<FnParams<Fn>> | undefined
  select?: (data: FnReturn<Fn>) => TData
}

type OnlySuccessType<TData> = InfiniteQueryObserverSuccessResult<TData>
type AllTypes<TData> = UseInfiniteQueryResult<TData>

type IsSuspenseType<TData, Suspense> = Suspense extends true
  ? OnlySuccessType<TData>
  : AllTypes<TData>

type IsEnabledType<TData, Suspense, Enabled> = Enabled extends true
  ? IsSuspenseType<TData, Suspense>
  : Enabled extends boolean
  ? AllTypes<TData>
  : IsSuspenseType<TData, Suspense>

type Return<TData, Suspense, Enabled, InitialData> =
  InitialData extends undefined
    ? IsEnabledType<TData, Suspense, Enabled>
    : OnlySuccessType<TData>

type NoArgs<Fn extends FnBase> = {
  <
    TData = FnReturn<Fn>,
    Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
    Enabled extends boolean | undefined = undefined,
    InitialData extends BaseInitialData<Fn> = undefined
  >(
    args?: FnParams<Fn>,
    options?: Options<Fn, TData, Suspense, Enabled, InitialData>
  ): Return<TData, Suspense, Enabled, InitialData>

  <
    TData = FnReturn<Fn>,
    Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
    Enabled extends boolean | undefined = undefined,
    InitialData extends BaseInitialData<Fn> = undefined
  >(
    options?: Options<Fn, TData, Suspense, Enabled, InitialData>
  ): Return<TData, Suspense, Enabled, InitialData>
}

type AnyArgs<Fn extends FnBase> = <
  TData = FnReturn<Fn>,
  Suspense extends boolean | undefined = typeof DEFAULT_SUSPENSE,
  Enabled extends boolean | undefined = undefined,
  InitialData extends BaseInitialData<Fn> = undefined
>(
  args: FnParams<Fn>,
  options?: Options<Fn, TData, Suspense, Enabled, InitialData>
) => Return<TData, Suspense, Enabled, InitialData>

export type UseInfiniteQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? NoArgs<Fn>
  : AnyArgs<Fn>

export const getUseInfiniteQuery = (
  fn: FnBase,
  path: string[]
): UseInfiniteQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const [options] = rest
    const key = ["infinite", ...path, ...args]

    const fetchWithPageParam: QueryFunction<FnReturn<FnBase>> = ({
      pageParam,
    }) => {
      const mergedParams = merge([], args, pageParam)
      return fn(...mergedParams)
    }

    const select: UseInfiniteQueryOptions["select"] = options.select
      ? (data) => ({
          ...data,
          pages: data.pages.map(options.select),
        })
      : undefined

    const suspense = options?.suspense ?? DEFAULT_SUSPENSE
    const withDefaults = { ...options, suspense, select }

    return useInfiniteQuery(key, fetchWithPageParam, withDefaults)
  }
}
