import { auth } from "auth"
import { v3 } from "backoffice-api"
import { isFetchError } from "fetcher"

type NewsPost = v3["getNewsPost"]["data"]

export const toggleLike = async (post: NewsPost) => {
  try {
    optimisticUpdate(post)
    await apiUpdate(post)
  } catch (error) {
    onError(error)
  } finally {
    invalidate(post)
  }
}

const apiUpdate = async (post: NewsPost) => {
  const { userId } = await auth.getActiveSession()

  if (post.is_liked) {
    const { data: postsAndReactions } = await v3.getNewsPost(post.id, {
      include: ["reactions"],
    })

    const myReactionId = postsAndReactions.reactions?.find(
      (reaction) => reaction.user_id === userId
    )?.id

    // Optimistic data. Not synced with api yet.
    if (!myReactionId) return

    await v3.deleteNewsPostReaction({
      news_post_id: post.id,
      reaction_id: myReactionId,
    })
  } else {
    const { data } = await v3.createNewsPostReaction(post.id)
    return data
  }
}

const optimisticUpdate = ({ id: postId }: NewsPost) => {
  v3.getNewsPost.setQueriesData([postId], ({ data }) => toggleIsLiked(data))
  v3.getNewsPosts.setQueriesData(({ data }) => {
    const post = data.find((post) => post.id === postId)
    if (post) toggleIsLiked(post)
  })
}

const invalidate = ({ id }: NewsPost) => {
  v3.getNewsPost.invalidateQueries([id])
  v3.getNewsPosts.invalidateQueries()
}

const onError = (error: unknown) => {
  if (isFetchError(error) && (error.status === 422 || error.status === 404)) {
    /*
      This can happen when data gets out of sync with the api
      and we create a new reaction with an existing id
      or delete a nonexisting reaction.
      Just ignore the error...
    */
  } else {
    throw error
  }
}

const toggleIsLiked = (post: NewsPost) => {
  if (post.is_liked) {
    post.likes_count -= 1
    post.is_liked = false
  } else {
    post.likes_count += 1
    post.is_liked = true
  }
}
