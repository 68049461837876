import {
  isExactlyEqual,
  isPartiallyEqual,
  localeToString,
  parseLocale,
  type Locale,
} from "./Locale"

type GetLanguageProps = {
  defaultLocale: string
  allowedLocales: string[]
  browserLocales: readonly string[]
}
export const pickLocale = ({
  browserLocales,
  allowedLocales,
  defaultLocale,
}: GetLanguageProps) => {
  const locale = pickMatchingLocale(
    browserLocales.map(parseLocale),
    allowedLocales.map(parseLocale)
  )

  if (locale) {
    return localeToString(locale)
  }

  if (isLocaleValid(defaultLocale, allowedLocales)) {
    return defaultLocale
  }

  return allowedLocales[0] ?? defaultLocale
}

export const isLocaleValid = (locale: string, allowedLocales: string[]) => {
  return allowedLocales.includes(locale)
}

const pickMatchingLocale = (
  browserLocales: Locale[],
  allowedLocales: Locale[]
) => {
  for (const browserLocale of browserLocales) {
    const allowedLocale = findAllowedLocale(allowedLocales, browserLocale)
    if (allowedLocale) return allowedLocale
  }
}

const findAllowedLocale = (allowedLocales: Locale[], browserLocale: Locale) => {
  if (browserLocale.regionCode === undefined) {
    return allowedLocales.find((locale) =>
      isPartiallyEqual(browserLocale, locale)
    )
  }

  return allowedLocales.find((locale) => isExactlyEqual(browserLocale, locale))
}
