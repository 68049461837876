import attendeesIcon from "assets/icons/attendees.svg"
import durationIcon from "assets/icons/duration.svg"
import hostIcon from "assets/icons/host.svg"
import pinIcon from "assets/icons/pin.svg"
import roomIcon from "assets/icons/room.svg"
import { v3 } from "backoffice-api"
import { useToggle } from "hooks"
import { sortBy } from "lodash-es"
import { useWindowWidth } from "materia"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toggleAttending } from "src/bonzai/toggleAttending"
import { toggleWaitingList } from "src/bonzai/toggleWaitingList"

import {
  formatEventDate,
  formatEventDay,
  formatEventDayMonth,
  formatEventFullDate,
  formatEventLength,
  formatEventTime,
  getHasEventExpired,
  getIsEventFull,
  pickEventDate,
} from "src/dataUtilities/eventDataUtilities"
import { getIsUrl } from "src/dataUtilities/getIsUrl"
import { useGetOrdinal } from "src/dataUtilities/getOrdinal"
import { addToCalendar } from "src/tools/addToCalendar"
import { ProductModalActivity, ProductModalEvent } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type EventDate = v3["getProductCourse"]["data"]

type ProductModalEventLoaderProps = {
  productId: number
}
export const ProductModalEventLoader = (
  props: ProductModalEventLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalActivity.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalEventLoaderProps) => {
  const dates = useProductDates(productId)

  const [activeDateId, setActiveDateId] = useState(
    () => pickEventDate(dates)?.id
  )

  if (dates.length === 0) return <Empty />

  const activeDate = dates.find((date) => date.id === activeDateId) ?? dates[0]

  if (!activeDate) throw new Error("Unable to pick an active date")

  const getHeader = () => {
    if (activeDate.is_attending) {
      return <Attending activeDate={activeDate} productId={productId} />
    }

    if (activeDate.is_on_waiting_list) {
      return <WaitingList activeDate={activeDate} />
    }

    return (
      <NotAttending
        activeDate={activeDate}
        activeDateId={activeDateId}
        dates={dates}
        setActiveDateId={setActiveDateId}
      />
    )
  }

  return (
    <ProductModalEvent>
      {getHeader()}

      <InfoList activeDate={activeDate} />
      <Buttons activeDate={activeDate} />
    </ProductModalEvent>
  )
}

const Empty = () => {
  const { t } = useTranslation()

  return (
    <ProductModalEvent>
      <ProductModalEvent.Empty header={t("event.NO_DATES_AVAILABLE")} />
    </ProductModalEvent>
  )
}

type AttendingProps = {
  activeDate: EventDate
  productId: number
}
const Attending = ({ activeDate, productId }: AttendingProps) => {
  const { t, i18n } = useTranslation()

  const product = v3.getProduct.useQuery([productId], {
    select: (res) => res.data,
  })

  return (
    <>
      <ProductModalEvent.Ticket
        attendingText={t("event.ATTENDING")}
        date={formatEventFullDate(activeDate, i18n.language)}
        time={formatEventTime(activeDate, i18n.language)}
        isWaiting={false}
      />
      <ProductModalEvent.AddToCalendar
        text={t("event.ADD_TO_CALENDAR")}
        onClick={() => addToCalendar(product, activeDate)}
      />
    </>
  )
}

type WaitingListProps = {
  activeDate: EventDate
}
const WaitingList = ({ activeDate }: WaitingListProps) => {
  const { waiting_list_position } = activeDate
  const { t, i18n } = useTranslation()
  const getOrdinal = useGetOrdinal()

  return (
    <>
      <ProductModalEvent.Ticket
        attendingText={t("event.ON_WAITING_LIST_FOR")}
        date={formatEventFullDate(activeDate, i18n.language)}
        time={formatEventTime(activeDate, i18n.language)}
        isWaiting={true}
      />
      <ProductModalEvent.WaitingInfo
        text={`${getOrdinal(waiting_list_position)} ${t("event.IN_LINE")}`}
      />
    </>
  )
}

type NotAttendingProps = {
  dates: EventDate[]
  activeDate: EventDate
  activeDateId: string | undefined
  setActiveDateId: (id: string) => void
}
const NotAttending = ({
  dates,
  activeDate,
  activeDateId,
  setActiveDateId,
}: NotAttendingProps) => {
  const { t, i18n } = useTranslation()
  const [showMoreDates, toggleShowMoreDates] = useToggle(false)
  const { isBigWindow } = useWindowWidth()

  const hasExtraDates = dates.length >= 3
  const toggleShowMoreButton = isBigWindow && hasExtraDates
  const shouldLimitDates = isBigWindow && hasExtraDates && !showMoreDates
  const displayDates = shouldLimitDates ? dates.slice(0, 3) : dates

  const eventDates = displayDates.map((event) => {
    const { month, time } = formatEventDate(event, i18n.language)

    return (
      <ProductModalEvent.Date
        key={event.id}
        isCancelled={event.cancelled}
        isActive={activeDateId === event.id}
        onClick={() => setActiveDateId(event.id)}
        day={formatEventDay(event, i18n.language)}
        month={month}
        time={event.cancelled ? t("event.CANCELLED") : time}
      />
    )
  })

  const headerText = getIsEventFull(activeDate)
    ? t("event.NO_SPOTS_LEFT")
    : t("event.AVAILABLE_DATES")

  const togleShowMoreText = showMoreDates
    ? `${t("event.LESS")}`
    : `${t("event.MORE")}`

  return (
    <>
      <ProductModalEvent.Header
        color={getIsEventFull(activeDate) ? "red" : undefined}
        text={headerText}
      />

      <ProductModalEvent.Dates isOverflow>
        {eventDates}
        {toggleShowMoreButton && (
          <ProductModalEvent.ToggleShowMore
            buttonText={togleShowMoreText}
            onClick={toggleShowMoreDates}
          />
        )}
      </ProductModalEvent.Dates>
    </>
  )
}

type InfoListProps = {
  activeDate: EventDate
}
const InfoList = ({ activeDate }: InfoListProps) => {
  const { i18n, t } = useTranslation()

  const {
    room,
    instructor,
    attendees_count,
    is_on_waiting_list,
    attendees_count_max,
    location,
  } = activeDate

  return (
    <ProductModalEvent.InfoList isOpaque={is_on_waiting_list}>
      <ProductModalEvent.Info
        icon={pinIcon}
        label={t("event.ADDRESS")}
        value={location}
        isUrl={getIsUrl(location)}
      />
      <ProductModalEvent.Info
        icon={durationIcon}
        label={t("event.DURATION")}
        value={formatEventLength(activeDate, i18n.language)}
      />
      <ProductModalEvent.Info
        icon={hostIcon}
        label={t("event.HOST")}
        value={instructor}
      />
      {room && (
        <ProductModalEvent.Info
          icon={roomIcon}
          label={t("event.ROOM")}
          value={room}
        />
      )}
      <ProductModalEvent.Info
        icon={attendeesIcon}
        label={t("event.ATTENDEES")}
        value={`${attendees_count}/${attendees_count_max}`}
        color={getIsEventFull(activeDate) ? "red" : undefined}
      />
    </ProductModalEvent.InfoList>
  )
}

type ButtonsProps = {
  activeDate: EventDate
}
const Buttons = ({ activeDate }: ButtonsProps) => {
  const { is_attending, is_on_waiting_list } = activeDate

  const getButton = () => {
    if (is_attending) {
      return <UnregisterButton onClick={() => toggleAttending(activeDate)} />
    }

    if (is_on_waiting_list) {
      return <UnregisterButton onClick={() => toggleWaitingList(activeDate)} />
    }

    if (getIsEventFull(activeDate)) {
      return (
        <WaitingListButton
          activeDate={activeDate}
          onClick={() => toggleWaitingList(activeDate)}
        />
      )
    }

    return (
      <AttendButton
        activeDate={activeDate}
        onClick={() => toggleAttending(activeDate)}
      />
    )
  }

  return <ProductModalEvent.Buttons>{getButton()}</ProductModalEvent.Buttons>
}

type WaitingListButtonProps = {
  activeDate: EventDate
  onClick: () => void
}
const WaitingListButton = ({ activeDate, onClick }: WaitingListButtonProps) => {
  const { t, i18n } = useTranslation()
  return (
    <ProductModalEvent.Button variant="waiting-list" onClick={onClick}>
      {t("event.PLACE_ME_ON_WAITING_LIST")}{" "}
      {formatEventDayMonth(activeDate, i18n.language)}
    </ProductModalEvent.Button>
  )
}

type AttendButtonProps = {
  activeDate: EventDate
  onClick: () => void
}
const AttendButton = ({ activeDate, onClick }: AttendButtonProps) => {
  const { t, i18n } = useTranslation()
  return (
    <ProductModalEvent.Button variant="attend" onClick={onClick}>
      {t("event.ATTEND")} {formatEventDayMonth(activeDate, i18n.language)}
    </ProductModalEvent.Button>
  )
}

type UnregisterButtonProps = {
  onClick: () => void
}
const UnregisterButton = ({ onClick }: UnregisterButtonProps) => {
  const { t } = useTranslation()
  return (
    <ProductModalEvent.Button variant="unregister" onClick={onClick}>
      {t("event.UNREGISTER")}
    </ProductModalEvent.Button>
  )
}

const useProductDates = (productId: number) => {
  return v3.getProduct.useQuery([productId, { include: ["courses"] }], {
    select: (res) => getActiveEvents(res.data.courses ?? []),
  })
}

const getActiveEvents = (courses: EventDate[]) => {
  const activeEvents = courses.filter((date) => !getHasEventExpired(date))
  return sortBy(activeEvents, (event) => event.start_datetime)
}
