import { bonzai } from "bonzai"
import { runFetch } from "fetcher"
import { assert, number, string, Struct, type } from "superstruct"
import { getConfig } from "./config"

/**
 * This file is temporory,
 * and emulates what might be stored in auth/session service in the future
 */

type GetSessionDataParams = {
  accessToken: string
  subdomain: string
}
export const getSessionData = async ({
  accessToken,
  subdomain,
}: GetSessionDataParams) => {
  const [user, company] = await Promise.all([
    api.getUser.fetchQuery([{ accessToken, subdomain }]),
    api.getCompany.fetchQuery([{ accessToken, subdomain }]),
  ])
  return {
    accessToken: accessToken,
    companyNameKey: company.name_key,
    subdomain: subdomain,
    userId: user.id,
    logo: company.logo,
  }
}

const UserResponse = type({
  id: number(),
})

const getUser = async ({ accessToken, subdomain }: GetSessionDataParams) => {
  const { backofficeUrl, apiToken } = await getConfig()
  return runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/users/me`,
    transform: (data) => validate(UserResponse, data),
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${accessToken}`,
      subdomain: subdomain,
    },
  })
}

const CompanyResponse = type({
  logo: string(),
  name_key: string(),
})

const getCompany = async ({ accessToken, subdomain }: GetSessionDataParams) => {
  const { backofficeUrl, apiToken } = await getConfig()

  return runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/public/companies/current`,
    transform: (data) => validate(CompanyResponse, data),
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${accessToken}`,
      subdomain: subdomain,
    },
  })
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}

// Use bonzai for automatic retries
const api = bonzai({
  getUser,
  getCompany,
})
