import { isFetchError, runFetch } from "fetcher"
import { assert, string, Struct, type } from "superstruct"
import { getConfig } from "./config"

const TransferTokenResponse = type({
  TransferKey: string(),
})

export const accessTokenToTransferKey = async (accessToken: string) => {
  const { authUrl } = await getConfig()
  const data = await runFetch({
    baseUrl: authUrl,
    path: "/api/v1/sessions/transfer",
    method: "PUT",
    transform: (data) => validate(TransferTokenResponse, data),
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return data.TransferKey
}

const TokenResponse = type({
  Token: string(),
})

export const transferKeyToAccessToken = async (transferKey: string) => {
  const { authUrl } = await getConfig()
  try {
    const data = await runFetch({
      baseUrl: authUrl,
      method: "GET",
      path: `/api/v1/sessions/transfer/${transferKey}`,
      transform: (data) => validate(TokenResponse, data),
    })
    return removeBearerPrefix(data.Token)
  } catch (error) {
    const isAlreadyUsed =
      isFetchError(error) &&
      error.status === 400 &&
      error.text === "Transfer Key Already Used"
    if (isAlreadyUsed) {
      console.warn("Transfer key is already used")
      return
    }
    throw error
  }
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}

const removeBearerPrefix = (value: string) => {
  return value.replace(/bearer\s*/i, "").trim()
}
