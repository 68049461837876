import { v3 } from "backoffice-api"
import { changeLocale } from "src/i18n/changeLocale"
import { sendVuplexMessage } from "src/vuplex/sendVuplexMessage"
import { subscribeToVuplex } from "src/vuplex/subscribeToVuplex"

export const initializeVuplex = () => {
  sendVuplexMessage({ type: "READY" })
  sendVuplexMessage({ type: "LEGACY_MODE" })

  subscribeToVuplex("SET_LANGUAGE", (event) => {
    onLanguageUpdate(event.payload.localeCode)
  })
}

const onLanguageUpdate = async (locale: string) => {
  const isValid = await isLocaleValid(locale)
  if (!isValid) return

  changeLocale(locale)
}

const isLocaleValid = async (locale: string) => {
  const res = await v3.getCurrentCompanySettings.fetchQueryOnce()

  const supportedLocales = res.data.supported_locales
  const localeCodes = supportedLocales.map((locale) => locale.code)
  return localeCodes.includes(locale)
}
