import { Button } from "materia"
import {
  forwardRef,
  useId,
  type FormHTMLAttributes,
  type InputHTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
} from "react"
import s from "./UserSettingsView.module.scss"

export const UserSettingsView = ({ children }: PropsWithChildren) => (
  <div className={s.view}>{children}</div>
)

type FormProps = FormHTMLAttributes<HTMLFormElement>
const Form = (props: FormProps) => (
  <form className={s.userSettings} {...props} />
)

type TopProps = {
  avatarUrl: string
  userName: string
  userPosition: string
}
const Top = ({ avatarUrl, userName, userPosition }: TopProps) => (
  <div className={s.top}>
    <img className={s.top__avatar} src={avatarUrl} alt="" />
    <div className={s.top__name}>
      <div className={s.top__username}>{userName}</div>
      <div className={s.top__department}>{userPosition}</div>
    </div>
  </div>
)

const Buttons = ({ children }: PropsWithChildren) => (
  <div className={s.buttons}>{children}</div>
)

type AvatarsProps = {
  label: string
  children: ReactNode
}
const Avatars = ({ label, children }: AvatarsProps) => (
  <div className={s.avatars}>
    <label className={s.avatars__label}>{label}</label>
    <div className={s.avatars__avatars}>{children}</div>
  </div>
)

type AvatarProps = InputHTMLAttributes<HTMLInputElement> & {
  src: string
}
const Avatar = forwardRef<HTMLInputElement, AvatarProps>(
  ({ src, ...props }, ref) => {
    const id = useId()

    return (
      <div className={s.avatar}>
        <input
          type="radio"
          id={id}
          className={s.avatar__input}
          ref={ref}
          {...props}
        />
        <label className={s.avatar__label} htmlFor={id}>
          <img
            className={s.avatar__image}
            src={src}
            alt="Avatar"
            loading="lazy"
          />
        </label>
      </div>
    )
  }
)

UserSettingsView.Form = Form
UserSettingsView.Top = Top
UserSettingsView.Button = Button
UserSettingsView.Buttons = Buttons
UserSettingsView.Avatars = Avatars
UserSettingsView.Avatar = Avatar
