import { useWindowWidth } from "materia"
import type { ReactNode } from "react"
import { getChromaticProps } from "../../../utils/getChromaticProps"
import { KpiSalesWidget } from "../KpiSalesWidget"
import { LeaderboardWidget } from "../LeaderboardWidget"
import { NewsWidget } from "../NewsWidget"
import { RecentActivityWidget } from "../RecentActivityWidget"
import { UserWidget } from "../UserWidget"
import s from "./WidgetLayout.module.scss"

type WidgetLayoutProps = {
  widgets: ReactNode
  children: ReactNode
  chromaticIgnore?: boolean
}
export const WidgetLayout = ({
  children,
  widgets,
  chromaticIgnore,
}: WidgetLayoutProps) => {
  const { isBigWindow } = useWindowWidth()

  return (
    <div className={s.widgetLayout}>
      <div className={s.widgetLayout__content}>{children}</div>
      {isBigWindow && (
        <aside
          className={s.widgetLayout__widgets}
          {...getChromaticProps(chromaticIgnore)}
        >
          {widgets}
        </aside>
      )}
    </div>
  )
}

const WidgetsSkeleton = () => (
  <>
    <UserWidget.Skeleton />
    <NewsWidget.Skeleton />
    <LeaderboardWidget.Skeleton />
    <KpiSalesWidget.Skeleton />
    <RecentActivityWidget.Skeleton />
  </>
)

WidgetLayout.WidgetsSkeleton = WidgetsSkeleton
