import { ReactComponent as PlayButtonIcon } from "assets/icons/playButton.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { ProgressCircle } from "materia"
import type { PropsWithChildren } from "react"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import s from "./ProductPlaying.module.scss"

type Props = {
  link: FiestaLinkProps
  image: string
  progress: number
  progressMax: number
  stars: number
  starsMax: number
  title: string
}

export const ProductPlaying = (props: Props) => {
  const { link } = props

  return (
    <FiestaLink className={s.productPlaying} {...link}>
      <Image {...props} />
      <Column>
        <Title {...props} />
        <Stars {...props} />
      </Column>
      <Play {...props} />
    </FiestaLink>
  )
}

const Image = ({ image }: Props) => (
  <img className={s.image} src={image} alt="" />
)

const Column = ({ children }: PropsWithChildren) => (
  <div className={s.column}>{children}</div>
)

const Title = ({ title }: Props) => <div className={s.title}>{title}</div>

const Stars = ({ starsMax, stars }: Props) => {
  const starElements = times(starsMax, (index) => (
    <img
      key={index}
      className={s.stars__star}
      src={index < stars ? starFilled : starEmpty}
      alt=""
    />
  ))

  return <div className={s.stars}>{starElements}</div>
}

const Play = ({ progress, progressMax }: Props) => (
  <div className={s.play}>
    <div className={s.play__progress}>
      <ProgressCircle
        value={progress}
        maxValue={progressMax}
        color="coal"
        backgroundColor="marble"
        stroke={11}
      />
    </div>
    <PlayButtonIcon className={s.play__playIcon} />
  </div>
)
