import type { v3 } from "backoffice-api"
import { pickTextAsync } from "src/i18n/usePickText"
import { sendVuplexMessage } from "./sendVuplexMessage"

type Product = v3["getProduct"]["data"]

export const sendOpenProductMessage = async (
  product: Product,
  href: string
) => {
  sendVuplexMessage({
    type: "OPEN_PRODUCT",
    payload: {
      id: Number(product.id),
      companyId: product.company_id,
      type: product.product_type.identifier,
      title: await pickTextAsync(product.title),
      description: await pickTextAsync(product.description_long, ""),
      image: product.product_image_url,
      link: href,
      googlePlayLink: product.google_play_link,
      appStoreLink: product.apple_store_link,
      openExternalBrowser: product.open_external_browser,
    },
  })
}
