import type { SetDataOptions } from "@tanstack/react-query"
import produce from "immer"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = SetDataOptions
type Return<Fn extends FnBase> = FnReturn<Fn> | undefined
type Updater<Fn extends FnBase> =
  | FnReturn<Fn>
  | ((oldData: FnReturn<Fn> | undefined) => FnReturn<Fn> | undefined | void)

export type SetQueryData<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args: FnParams<Fn>, updater: Updater<Fn>, options?: Options): Return<Fn>
      (updater: Updater<Fn>, options?: Options): Return<Fn>
    }
  : (args: FnParams<Fn>, updater: Updater<Fn>, options?: Options) => Return<Fn>

export const getSetQueryData = (path: string[]): SetQueryData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const [updater, options] = rest
    const key = [...path, ...args]

    return queryClient.setQueryData(
      key,
      typeof updater === "function" ? produce(updater) : updater,
      options
    )
  }
}
