import type { BrowserOptions } from "@sentry/react"
import * as Sentry from "@sentry/react"
import { auth } from "auth"
import { isFetchError, isValidationError, type FetchError } from "fetcher"
import { never } from "utils"

type Options = {
  isEnabled: boolean
  sentryUrl: string
  environment: string
  release: string
}

export const initializeSentry = async ({
  isEnabled,
  sentryUrl,
  environment,
  release,
}: Options) => {
  if (!isEnabled) return never()

  Sentry.init({
    dsn: sentryUrl,
    environment: environment,
    tracesSampleRate: 1,
    release: release,
    integrations: [new Sentry.BrowserTracing()],
    beforeSend: onBeforeSend,
  })

  const { userId, companyNameKey, subdomain, impersonation } =
    await auth.getActiveSession()

  Sentry.setUser({ id: String(userId) })

  Sentry.setTag("company_name_key", companyNameKey)

  Sentry.setContext("User Session", {
    "Company name key": companyNameKey,
    Subdomain: subdomain,
    Impersonation: impersonation,
  })

  return Sentry
}

const onBeforeSend: BrowserOptions["beforeSend"] = (event, hint) => {
  const error = hint.originalException

  if (isFetchError(error)) {
    event.level = isValidationError(error) ? "error" : "warning"
    event.fingerprint = getErrorFingerprint(error)
    event.contexts = { ...event.contexts, Request: getErrorContext(error) }
  }

  return event
}

const getErrorContext = (error: FetchError) => ({
  Method: error.method,
  URL: error.url,
  "Scrubbed URL": error.scrubbedUrl,
  "Query qarams": error.params,
  "Status code": error.status,
  "Is online?": error.isOnline,
  "Did request fail?": error.didRequestFail,
})

const getErrorFingerprint = (error: FetchError) => [
  error.name,
  error.message,
  error.method,
  String(error.status),
  error.scrubbedUrl,
]
