import { createResolvable } from "utils"

const { promise, resolve } = createResolvable<Vuplex>()

export const getVuplex = () => promise

const resolveVuplex = () => {
  if (window.vuplex === undefined) throw new Error("Vuplex is not defined")
  resolve(window.vuplex)
}

if ("vuplex" in window) {
  resolveVuplex()
} else {
  window.addEventListener("vuplexready", resolveVuplex)
}
