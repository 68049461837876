import { unknown } from "superstruct"
import { getCompanyNameKey, getUserId } from "../sessionUtils"
import * as http from "./v3Http"
import type {
  DeleteNewsPostReactionParams,
  GetBundleProductsParams,
  GetContentInCategoryParams,
  GetGroupParams,
  GetKpiSalesParams,
  GetNewsPostParams,
  GetNewsPostsParams,
  GetProductCourseParams,
  GetProductParams,
  GetProductsParams,
  UpdateActivityItemParams,
  UpdateUserSettingsParams,
} from "./v3Params"
import {
  ActivityItemsResponse,
  ActivityProgressResponse,
  BundleProductsResponse,
  BundleResponse,
  BundleStatusResponse,
  CategoryContentResponse,
  CompanyResponse,
  CompanySettingsResponse,
  CreateNewsPostReactionResponse,
  DepartmentsResponse,
  GroupResponse,
  GroupUsersResponse,
  KpiSalesResponse,
  NewsPostResponse,
  NewsPostsResponse,
  ProductCategoryProgressResponse,
  ProductCourseResponse,
  ProductProgressResponse,
  ProductResponse,
  ProductsResponse,
  UpdateActivityItemResponse,
  UserProductResponse,
  UserResponse,
  UserSettingsResponse,
} from "./v3Responses"

export const getCurrentUser = async () => {
  return http.get(UserResponse, `/users/current`)
}

export const getCurrentCompany = async () => {
  const companyName = await getCompanyNameKey()
  return http.get(CompanyResponse, `/companies/${companyName}`)
}

export const getCurrentCompanySettings = async () => {
  const companyName = await getCompanyNameKey()
  return http.get(CompanySettingsResponse, `/companies/${companyName}/settings`)
}

export const getNewsPosts = async (params: GetNewsPostsParams = {}) => {
  const companyName = await getCompanyNameKey()
  const sort = "-published_at"
  const withInclude = { include: "image,reactions", sort, ...params }
  return http.get(
    NewsPostsResponse,
    `/companies/${companyName}/posts`,
    withInclude
  )
}

export const getNewsPost = (
  newsPostId: string,
  params: GetNewsPostParams = {}
) => {
  const include = ["image", ...(params.include ?? [])].join(",")
  const merged = { ...params, include }
  return http.get(NewsPostResponse, `/posts/${newsPostId}`, merged)
}

export const createNewsPostReaction = (newsPostId: string) => {
  const data = { reaction_type: "like" }
  const path = `/posts/${newsPostId}/reactions`
  return http.post(CreateNewsPostReactionResponse, path, data)
}

export const deleteNewsPostReaction = ({
  news_post_id,
  reaction_id,
}: DeleteNewsPostReactionParams) => {
  return http.del(`/posts/${news_post_id}/reactions/${reaction_id}`)
}

export const getActivityItems = (productId: number) => {
  return http.get(ActivityItemsResponse, `products/${productId}/activity_items`)
}

export const getActivityProgress = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}/activity_progress`
  return http.get(ActivityProgressResponse, url)
}

export const getCurrentUserProduct = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}`
  return http.get(UserProductResponse, url)
}

export const updateActivityItem = async ({
  product_id,
  ...params
}: UpdateActivityItemParams) => {
  const user_id = await getUserId()
  const url = `products/${product_id}/activity_item_users`
  return http.post(UpdateActivityItemResponse, url, { user_id, ...params })
}

export const getProduct = (
  // This reconciles V1 (id === number) and V3 (id === string)
  productId: number | string,
  { include }: GetProductParams = {}
) => {
  const params = { include: include?.join(",") }
  return http.get(ProductResponse, `products/${productId}`, params)
}

export const getProductProgress = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}/progress`
  return http.get(ProductProgressResponse, url)
}

export const getProductCourse = (
  course_id: string,
  { include }: GetProductCourseParams = {}
) => {
  const params = { include: include?.join(",") }
  return http.get(ProductCourseResponse, `courses/${course_id}`, params)
}

export const getGroup = (
  group_id: string,
  { include }: GetGroupParams = {}
) => {
  const params = { include: include?.join(",") }
  return http.get(GroupResponse, `groups/${group_id}`, params)
}

export const getGroupUsers = (group_id: string) => {
  return http.get(GroupUsersResponse, `groups/${group_id}/users`)
}

export const addMyUserToGroup = async (group_id: string) => {
  const userId = await getUserId()
  return http.post(unknown(), `users/${userId}/groups`, { group_id })
}

export const removeMyUserFromGroup = async (group_id: string) => {
  const userId = await getUserId()
  return http.del(`users/${userId}/groups/${group_id}`)
}

export const getProductCategoryProgress = async (categoryId: number) => {
  const userId = await getUserId()
  const path = `users/${userId}/product_categories/${categoryId}/progress`
  return http.get(ProductCategoryProgressResponse, path)
}

export const getMyKpiSales = async (params: GetKpiSalesParams) => {
  const userId = await getUserId()
  const path = `users/${userId}/kpi_sales`
  return http.get(KpiSalesResponse, path, params)
}

export const getDepartmentKpiSales = async (
  departmentId: string,
  params: GetKpiSalesParams
) => {
  const path = `groups/${departmentId}/kpi_sales`
  return http.get(KpiSalesResponse, path, params)
}

export const getUserDepartments = async () => {
  const userId = await getUserId()
  return http.get(DepartmentsResponse, `users/${userId}/departments`)
}

export const getProducts = async (params: GetProductsParams) => {
  const userId = await getUserId()
  return http.get(ProductsResponse, `users/${userId}/products`, params)
}

export const getContentInCategory = async ({
  include,
  categoryId,
  ...rest
}: GetContentInCategoryParams) => {
  const userId = await getUserId()
  const path = `users/${userId}/product_categories/${categoryId}/contents`
  const params = { include: include?.join(","), ...rest }
  return http.get(CategoryContentResponse, path, params)
}

export const getBundle = async (bundleId: string) => {
  const path = `/product_bundles/${bundleId}`
  return http.get(BundleResponse, path)
}

export const getBundleProducts = async (params: GetBundleProductsParams) => {
  const userId = await getUserId()
  const path = `users/${userId}/product_bundles/${params.bundleId}/products`
  return http.get(BundleProductsResponse, path, params)
}

export const getBundleProgress = async (bundleId: string) => {
  const userId = await getUserId()
  const path = `users/${userId}/product_bundles/${bundleId}/progress`
  return http.get(BundleStatusResponse, path)
}

export const getUserSettings = async () => {
  const userId = await getUserId()
  return http.get(UserSettingsResponse, `users/${userId}/settings`)
}

export const updateUserSettings = async (data: UpdateUserSettingsParams) => {
  const userId = await getUserId()
  return http.patch(UserSettingsResponse, `users/${userId}/settings`, data)
}
