import type { ReactNode } from "react"
import type { MateriaColor } from "../../types/MateriaColor"
import { getCssColor } from "../../utils/getCssColor"
import s from "./ProgressCircle.module.scss"

type ProgressCircleProps = {
  value: number
  stroke?: number
  maxValue?: number
  color?: MateriaColor
  backgroundColor?: MateriaColor
  fillBackground?: boolean
  children?: ReactNode
}

export const ProgressCircle = ({
  value,
  maxValue = 100,
  stroke = 5,
  color = "green-30",
  backgroundColor = "paper",
  fillBackground = false,
  children,
}: ProgressCircleProps) => {
  const center = 50
  const radius = center - stroke / 2

  return (
    <div className={s.progress}>
      <svg className={s.progress__svg} viewBox="0 0 100 100">
        <circle
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={stroke}
          stroke={getCssColor(backgroundColor)}
          fill={fillBackground ? getCssColor(backgroundColor) : "none"}
        />
        <circle
          className={s.progress__circle}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={stroke}
          fill="none"
          strokeLinecap="round"
          pathLength="1"
          stroke={getCssColor(color)}
          strokeDasharray={`${value / maxValue}px 1px`}
        />
      </svg>
      <div className={s.progress__content}>{children}</div>
    </div>
  )
}
