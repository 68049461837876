import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { getProductLink } from "src/dataUtilities/productDataUtilities"
import { ProductCardActivity } from "ui/exports/portal"

type ProductCardActivityLoaderProps = {
  productId: number
  productTitle: string
}
export const ProductCardActivityLoader = ({
  productId,
  productTitle,
}: ProductCardActivityLoaderProps) => {
  const { t } = useTranslation()

  const [status, progress] = useData(productId)

  const { progress_count, progress_of } = progress
  const isCompleted = progress_count === progress_of

  return (
    <ProductCardActivity
      link={{ to: getProductLink(productId, "activity") }}
      completedCount={progress_count}
      totalCount={progress_of}
      isCompleted={isCompleted}
      isCertified={status?.certified ?? false}
      isMandatory={status?.is_mandatory ?? false}
      title={productTitle}
      info={t("product.PRODUCT_TYPES.activity")}
      mandatoryText={t("user.MANDATORY")}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }], {
      suspense: false,
    }),

    v3.getActivityProgress.useQuery([productId], {
      select: (res) => res.data,
    }),
  ])
}
