import { createContext, useContext } from "react"
import type { useTooltip } from "./useTooltip"

type ContextType = ReturnType<typeof useTooltip>

const TooltipContext = createContext<ContextType | null>(null)

export const TooltipContextProvider = TooltipContext.Provider

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context === null)
    throw new Error(
      "Tooltip is used incorrectly. <Tooltip.Trigger /> and <Tooltip.Content /> cannot be used without a parent <Tooltip /> component"
    )

  return context
}
