import type { v3 } from "backoffice-api"
import { formatDate } from "format"
import { upperFirst } from "lodash-es"
import { getTimeDifference } from "./getTimeDifference"

type EventDate = v3["getProductCourse"]["data"]

export const formatEventDate = (eventDate: EventDate, locale: string) => {
  const month = formatDate(eventDate.start_datetime, locale, { month: "short" })
  const day = formatDate(eventDate.start_datetime, locale, { day: "2-digit" })
  const time = formatDate(eventDate.start_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  return { month, day, time }
}

export const formatEventFullDate = (eventDate: EventDate, locale: string) => {
  const formatted = formatDate(eventDate.start_datetime, locale, {
    weekday: "long",
    month: "short",
    day: "numeric",
  })
  return upperFirst(formatted)
}

export const formatEventTime = (eventDate: EventDate, locale: string) => {
  const startDate = formatDate(eventDate.start_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  const endDate = formatDate(eventDate.end_datetime, locale, {
    hour: "numeric",
    minute: "numeric",
  })

  return `${startDate} - ${endDate}`
}

export const formatEventDayMonth = (eventDate: EventDate, locale: string) => {
  const startDateTime = new Date(eventDate.start_datetime)
  const endDateTime = new Date(eventDate.end_datetime)

  const startDate = formatDate(startDateTime, locale, {
    day: "numeric",
    month: "short",
  })
  const endDate = formatDate(endDateTime, locale, {
    day: "numeric",
    month: "short",
  })

  if (startDateTime.getDay() !== endDateTime.getDay())
    return `${startDate} - ${endDate}`
  return startDate
}

export const formatEventDay = (eventDate: EventDate, locale: string) => {
  const startDateTime = new Date(eventDate.start_datetime)
  const endDateTime = new Date(eventDate.end_datetime)

  const startDate = formatDate(startDateTime, locale, { day: "numeric" })
  const endDate = formatDate(endDateTime, locale, { day: "numeric" })

  if (startDateTime.getDay() !== endDateTime.getDay())
    return `${startDate}-${endDate}`
  return startDate
}

export const formatEventLength = (eventDate: EventDate, locale: string) => {
  const displayNamesIsNotSupported = Intl.DisplayNames === undefined
  if (displayNamesIsNotSupported) return ""

  let names: Intl.DisplayNames
  try {
    names = new Intl.DisplayNames(locale, {
      // Some older phones don't support dateTimeField
      type: "dateTimeField",
      style: "short",
    })
  } catch (error) {
    console.error(error)
    return ""
  }

  const startDate = new Date(eventDate.start_datetime)
  const endDate = new Date(eventDate.end_datetime)
  const { seconds, days, hours, minutes, months, weeks, years } =
    getTimeDifference(startDate, endDate)

  if (years > 0) return `${years} ${names.of("year")}`
  if (months > 0) return `${months} ${names.of("month")}`
  if (weeks > 0) return `${weeks} ${names.of("weekOfYear")}`
  if (days > 0) return `${days} ${names.of("day")}`
  if (hours > 0) return `${hours} ${names.of("hour")}`
  if (minutes > 0) return `${minutes} ${names.of("minute")}`
  return `${seconds} ${names.of("second")}`
}

export const pickEventDate = (dates: EventDate[]) => {
  const validDates = dates.filter((date) => !date.cancelled)
  const firstAttendingDate = validDates.find(
    (date) => date.is_attending || date.is_on_waiting_list
  )
  const firstDate = validDates[0]
  return firstAttendingDate ?? firstDate
}

export const getHasEventExpired = (event: EventDate) => {
  const eventDate = new Date(event.start_datetime).getTime()
  return eventDate <= Date.now()
}

export const getIsEventFull = (event: EventDate | undefined) => {
  if (event === undefined) return false

  return event.attendees_count >= event.attendees_count_max
}
