import type { QueryFilters } from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = QueryFilters
type Return<Fn extends FnBase> = FnReturn<Fn> | undefined

export type GetQueryData<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args: FnParams<Fn>, options?: Options): Return<Fn>
      (options?: Options): Return<Fn>
    }
  : (args: FnParams<Fn>, options?: Options) => Return<Fn>

export const getGetQueryData = (path: string[]): GetQueryData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.getQueryData(key, ...rest)
  }
}
