import * as sentry from "sentry"
import {
  APP_ENV,
  GIT_COMMIT_SHA,
  IS_PROD_BUILD,
  VERCEL_GIT_COMMIT_SHA,
} from "../env"
import { APP_TYPE, APP_VERSION, IS_APP } from "../vuplex/constants"

const SENTRY_URL =
  "https://2ce3968eb57f430285a2f708d811ef15@o229568.ingest.sentry.io/4504684345032704"

export const initializeSentry = async () => {
  const release = GIT_COMMIT_SHA || VERCEL_GIT_COMMIT_SHA
  if (!release) return

  const Sentry = await sentry.initializeSentry({
    isEnabled: IS_PROD_BUILD,
    sentryUrl: SENTRY_URL,
    environment: APP_ENV,
    release,
  })

  Sentry.setContext("App", {
    "Is in app mode?": IS_APP,
    "App type": APP_TYPE,
    "App version": APP_VERSION,
  })
}
