import type { SetDataOptions } from "@tanstack/react-query"
import produce from "immer"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = SetDataOptions
type Return<Fn extends FnBase> = [
  readonly unknown[],
  FnReturn<Fn> | undefined
][]
type Arguments<Fn extends FnBase> = Partial<FnParams<Fn>>
type Updater<Fn extends FnBase> =
  | FnReturn<Fn>
  | ((oldData: FnReturn<Fn>) => FnReturn<Fn> | undefined | void)

export type SetQueriesData<Fn extends FnBase> = {
  (args: Arguments<Fn>, updater: Updater<Fn>, options?: Options): Return<Fn>
  (updater: Updater<Fn>, options?: Options): Return<Fn>
}

export const getSetQueriesData = (path: string[]): SetQueriesData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const [updater, options] = rest
    const key = [...path, ...args]

    return queryClient.setQueriesData(
      key,
      typeof updater === "function" ? wrapUpdater(updater) : updater,
      options
    )
  }
}

const wrapUpdater = (updater: any) => {
  return produce((data) => {
    const isDataLoading = data === undefined
    if (isDataLoading) return

    return updater(data)
  })
}
