import type { ReactNode } from "react"
import { getChromaticProps } from "../../../utils/getChromaticProps"
import { FeaturedProducts } from "../FeaturedProducts"
import { JourneyTeaser } from "../JourneyTeaser"
import { ProductList } from "../ProductList"
import s from "./ForYouView.module.scss"

type ForYouViewProps = {
  children: ReactNode
  chromaticIgnore?: boolean
}
export const ForYouView = ({ children, chromaticIgnore }: ForYouViewProps) => (
  <div className={s.view} {...getChromaticProps(chromaticIgnore)}>
    {children}
  </div>
)

type CategoryProps = {
  title: string
  children: ReactNode
}
const Category = ({ title, children }: CategoryProps) => (
  <div className={s.category}>
    <div className={s.category__title}>{title}</div>
    {children}
  </div>
)

const Skeleton = () => (
  <ForYouView>
    <FeaturedProducts.Skeleton />
    <JourneyTeaser.Skeleton />
    <CategorySkeleton />
    <CategorySkeleton />
    <CategorySkeleton />
  </ForYouView>
)

const CategorySkeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__title} />
    <ProductList.Skeleton />
  </div>
)

ForYouView.Category = Category
ForYouView.Skeleton = Skeleton
ForYouView.CategorySkeleton = CategorySkeleton
