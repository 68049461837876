import checkmark from "assets/icons/checkmark.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import lockedJourneyIcon from "assets/icons/lockedJourneyIcon.svg"
import { times } from "lodash-es"
import { ProgressCircle } from "materia"
import { Children, type PropsWithChildren, type ReactNode } from "react"
import { modifiers } from "ui-tools"
import { backgroundImageStyle } from "../../../utils/backgroundImageStyle"
import { toPercent } from "../../../utils/toPercent"
import { JourneyCard } from "../JourneyCard"
import s from "./Journey.module.scss"

type JourneyProps = {
  backgroundImage: string
  isLocked: boolean
  children: ReactNode
}
export const Journey = ({
  backgroundImage,
  isLocked,
  children,
}: JourneyProps) => (
  <div
    className={modifiers(s, "journey", { isLocked })}
    style={backgroundImageStyle(backgroundImage)}
  >
    {children}
  </div>
)

type HeaderProps = {
  onClick: () => void
  title: string
  isOpen: boolean
  isLocked: boolean
  isCertified: boolean
  progress: number
  progressMax: number
}
const Header = ({
  onClick,
  title,
  isOpen,
  isLocked,
  isCertified,
  progress,
  progressMax,
}: HeaderProps) => {
  return (
    <div className={modifiers(s, "header", { isOpen })}>
      <button
        className={s.header__button}
        onClick={onClick}
        title="Toggle journey"
      >
        {isLocked ? (
          <img className={s.header__locked} src={lockedJourneyIcon} alt="" />
        ) : isCertified ? (
          <img className={s.header__certified} src={checkmark} alt="" />
        ) : (
          <div className={s.header__progress}>
            <ProgressCircle
              color="white"
              backgroundColor="black"
              fillBackground
              value={progress}
              maxValue={progressMax}
              stroke={7}
            >
              {toPercent(progress, progressMax)}%
            </ProgressCircle>
          </div>
        )}

        <div className={s.header__title}>{title}</div>
        <div className={s.header__toggleContainer}>
          <img className={s.header__toggle} src={chevronIcon} alt="" />
        </div>
      </button>
    </div>
  )
}

type ProductsProps = {
  isOpen: boolean
  children: ReactNode
}
const Products = ({ isOpen, children }: ProductsProps) => {
  if (!isOpen) return null

  return (
    <div className={s.products}>
      <div className={s.startBlock} />
      <div>
        {Children.map(children, (child) => (
          <JourneyCardBlock>{child}</JourneyCardBlock>
        ))}
      </div>
    </div>
  )
}

type SkeletonProps = { isOpen: boolean }
const Skeleton = ({ isOpen }: SkeletonProps) => (
  <div className={s.skeleton}>
    <div className={s.skeleton__header}>
      <div className={s.skeleton__headerButton}>
        <div className={s.skeleton__progress} />
        <div className={s.skeleton__title} />
        <div className={s.skeleton__toggle} />
      </div>
    </div>
    <Journey.Products isOpen={isOpen}>
      {times(6, (index) => (
        <JourneyCardBlock key={index}>
          <JourneyCard.Skeleton />
        </JourneyCardBlock>
      ))}
    </Journey.Products>
  </div>
)

Journey.Header = Header
Journey.Products = Products
Journey.Skeleton = Skeleton

const JourneyCardBlock = ({ children }: PropsWithChildren) => (
  <div className={s.journeyBlock}>
    <div>{children}</div>
  </div>
)
