import type {
  InvalidateOptions,
  InvalidateQueryFilters,
} from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import type { FnBase, FnParams, FnReturn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = InvalidateOptions
type Filters<Fn extends FnBase> = InvalidateQueryFilters<FnReturn<Fn>>
type Return = Promise<void>
type Arguments<Fn extends FnBase> = Partial<FnParams<Fn>>

export type InvalidateQueries<Fn extends FnBase> = {
  (args?: Arguments<Fn>, filters?: Filters<Fn>, options?: Options): Return
  (filters?: Filters<Fn>, options?: Options): Return
}

export const getInvalidateQueries = (
  path: string[]
): InvalidateQueries<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.invalidateQueries(key, ...rest)
  }
}
