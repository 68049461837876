import { useWindowWidth } from "materia"
import type { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import s from "./FilterView.module.scss"

export const FilterView = ({ children }: PropsWithChildren) => <>{children}</>

type TopProps = {
  title: string
  clearFilterText: string
  clearFilterLink: FiestaLinkProps
}
const Top = ({ title, clearFilterText, clearFilterLink }: TopProps) => (
  <div className={s.top}>
    <div className={s.top__title}>{title}</div>
    <FiestaLink className={s.top__clear} {...clearFilterLink}>
      {clearFilterText}
    </FiestaLink>
  </div>
)

const Filters = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) {
    return null
  }

  return <div className={s.filters}>{children}</div>
}

type FilterProps = {
  title: string
  count: number
  isSelected: boolean
  onSelected: () => void
}
const Filter = ({ title, count, isSelected, onSelected }: FilterProps) => (
  <button
    className={modifiers(s, "filter", { isSelected })}
    onClick={onSelected}
  >
    {title} ({count})
  </button>
)

FilterView.Top = Top
FilterView.Filters = Filters
FilterView.Filter = Filter
