import { boolean, record, string, type, type Infer } from "superstruct"
import { never } from "utils"
import { getLokaliseFile } from "./getLokaliseFile"

const ManifestLocale = type({
  name: string(),
  fileName: string(),
  locale: string(),
  isRTL: boolean(),
})

export type ProjectManifest = Infer<typeof ProjectManifest>

const ProjectManifest = type({
  projectName: string(),
  baseLocale: string(),
  locales: record(string(), ManifestLocale),
})

export const getManifest = () => {
  if (import.meta.env.MODE === "test") {
    return never()
  }

  return getLokaliseFile(ProjectManifest, "manifest.json")
}

getManifest() // Fetch manifest early
