import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { auth } from "auth"
import { BonzaiProvider } from "bonzai"
import { PortalTarget } from "materia"
import { useState } from "react"
import { QueryBoundary } from "utility-components"
import { AppShellLoader } from "./components/AppShellLoader"
import { CompletedProductBannerLoader } from "./components/CompletedProductBannerLoader"
import { getRoutes } from "./routes"

export const App = () => (
  <BonzaiProvider>
    <PortalTarget>
      <QueryBoundary>
        <AppRouter />
        <CompletedProductBannerLoader />
      </QueryBoundary>
    </PortalTarget>
  </BonzaiProvider>
)

const AppRouter = () => {
  const session = auth.getActiveSession.useQuery()
  const [location] = useState(() => new ReactLocation())

  return (
    <Router
      routes={getRoutes()}
      location={location}
      basepath={session.subdomain}
    >
      <AppShellLoader>
        <Outlet />
      </AppShellLoader>
    </Router>
  )
}
