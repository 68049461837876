import kpiIcon from "assets/icons/kpiIcon.svg"
import { useWindowWidth } from "materia"
import type { PropsWithChildren } from "react"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { KpiSalesWidget } from "../KpiSalesWidget/KpiSalesWidget"
import s from "./KpiSalesHeader.module.scss"

export const KpiSalesHeader = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.kpiSalesHeader}>{children}</div>
}

type YourSalesProps = {
  label: string
  link: FiestaLinkProps
}
const YourSales = ({ label, link }: YourSalesProps) => (
  <FiestaLink className={s.yourSales} {...link}>
    <img className={s.yourSales__logo} src={kpiIcon} alt="" />
    <div className={s.yourSales__label}>{label}</div>
  </FiestaLink>
)

const Summary = ({ children }: PropsWithChildren) => (
  <div className={s.summary}>{children}</div>
)

KpiSalesHeader.YourSales = YourSales
KpiSalesHeader.Summary = Summary
KpiSalesHeader.SummaryItem = KpiSalesWidget.SummaryItem

export const KpiSalesHeaderSkeleton = () => <div className={s.skeleton}></div>
