import {
  useQueries,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query"
import type { FnBase, FnParams, FnReturn } from "./types"

type Options<Fn extends FnBase, TData> = Omit<
  UseQueryOptions<FnReturn<Fn>, unknown, TData>,
  "context" | "queryKey" | "queryFn"
>

export type UseQueries<Fn extends FnBase> = <TData = FnReturn<Fn>>(
  args: FnParams<Fn>[],
  options?: Options<Fn, TData>
) => UseQueryResult<TData>[]

export const getUseQueries = (
  fn: FnBase,
  path: string[]
): UseQueries<FnBase> => {
  return (argList, options) => {
    return useQueries({
      queries: argList.map((args) => ({
        queryKey: [...path, ...args],
        queryFn: () => fn(...args),
        ...(options as {}), // TODO: find a non-hacky way to make typescript happy
      })),
    })
  }
}
