import type { Session } from "./activeSession"
import { getConfig } from "./config"
import { revokeAccessToken } from "./revokeAccessToken"

const SESSIONS_KEY = "stored_sessions_v2"
const LEGACY_KEYS = ["stored_sessions_v1", "sessions_v1"]

type AddSessionToStorage = {
  session: Session
  rememberMe: boolean
}
export const addSession = async ({
  session,
  rememberMe,
}: AddSessionToStorage): Promise<void> => {
  const removedSessions = await removeSession(session)
  revokePreviousSessions(session, removedSessions)

  if (rememberMe) {
    await addPersistentSession(session)
  } else {
    await addTemporarySession(session)
  }
}

export const removeSession = async (session: Session) => {
  const [removedTemporarySessions, removedPersistentSessions] =
    await Promise.all([
      removeTemporarySession(session),
      removePersistentSession(session),
    ])
  return [...removedTemporarySessions, ...removedPersistentSessions]
}

export const getSessions = async () => {
  const [persistentSessions, temporarySessions] = await Promise.all([
    getPersistentSessions(),
    getTemporarySessions(),
  ])
  return filterByEnvironment([...temporarySessions, ...persistentSessions])
}

const addPersistentSession = async (session: Session) => {
  save("persistent", [session, ...(await getPersistentSessions())])
}

const addTemporarySession = async (session: Session) => {
  save("temporary", [session, ...(await getTemporarySessions())])
}

const removePersistentSession = async (session: Session) => {
  const sessions = await getPersistentSessions()
  const removedSessions = sessions.filter(isSessionFilter(session))
  save("persistent", sessions.filter(isNotSessionFilter(session)))
  return removedSessions
}

const removeTemporarySession = async (session: Session) => {
  const sessions = await getTemporarySessions()
  const removedSessions = sessions.filter(isSessionFilter(session))
  save("temporary", sessions.filter(isNotSessionFilter(session)))
  return removedSessions
}

const getPersistentSessions = async () => {
  return get<Session[]>("persistent", SESSIONS_KEY) ?? []
}

const getTemporarySessions = async () => {
  return get<Session[]>("temporary", SESSIONS_KEY) ?? []
}

const save = (storage: "temporary" | "persistent", value: unknown) => {
  const data = JSON.stringify(value)
  try {
    if (storage === "persistent") {
      localStorage.setItem(SESSIONS_KEY, data)
    } else {
      sessionStorage.setItem(SESSIONS_KEY, data)
    }
  } catch (error) {
    console.error(error)
  }
}

const get = <T>(
  storage: "temporary" | "persistent",
  key: string
): T | undefined => {
  let data
  try {
    if (storage === "persistent") {
      data = localStorage.getItem(key)
    } else {
      data = sessionStorage.getItem(key)
    }
  } catch (error) {
    console.error(error)
  }

  if (!data) return
  return JSON.parse(data)
}

const isSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  isSession(sessionA, sessionB)

const isNotSessionFilter = (sessionA: Session) => (sessionB: Session) =>
  !isSession(sessionA, sessionB)

const isSession = (sessionA: Session, sessionB: Session) => {
  if (sessionA.subdomain !== sessionB.subdomain) {
    return false
  }
  if (sessionA.companyNameKey !== sessionB.companyNameKey) {
    return false
  }
  if (sessionA.environment !== sessionB.environment) {
    return false
  }
  return true
}

const revokePreviousSessions = async (
  session: Session,
  removedSessions: Session[]
) => {
  for (const sessionToDelete of removedSessions) {
    if (sessionToDelete.accessToken !== session.accessToken) {
      await revokeAccessToken(sessionToDelete)
    }
  }
}

const filterByEnvironment = async (sessions: Session[]) => {
  const { environment } = await getConfig()
  return sessions.filter((session) => session.environment === environment)
}

const removeLegacyData = async () => {
  const legacySessions = LEGACY_KEYS.flatMap(
    (key) => get<unknown[]>("persistent", key) ?? []
  )

  const promises = legacySessions.map(async (session: any) => {
    const accessToken = session?.accessToken ?? session?.access_token
    if (typeof accessToken !== "string") return
    await revokeAccessToken({ accessToken })
  })
  await Promise.allSettled(promises)

  deleteLegacyKeys()
}

const deleteLegacyKeys = () => {
  for (const key of LEGACY_KEYS) {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  }
}

if (import.meta.env.PROD) {
  removeLegacyData()
}
