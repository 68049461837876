import { auth } from "auth"
import { deserializeJsonApi } from "deserialize-json-api"
import { createFetcher, type FetchError, type QueryParams } from "fetcher"
import { assert, Struct } from "superstruct"
import { getConfig } from "../apiConfig"

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return request({
    method: "GET",
    path,
    params,
    transform: (data) => deserializeAndValidate(schema, data),
  })
}

export const post = <T>(schema: Struct<T>, path: string, attributes?: any) => {
  const data = { data: { attributes } }
  return request({
    method: "POST",
    path,
    data,
    transform: (data) => deserializeAndValidate(schema, data),
  })
}

export const patch = <T>(schema: Struct<T>, path: string, attributes?: any) => {
  const data = { data: { attributes } }
  return request({
    method: "PATCH",
    path,
    data,
    transform: (data) => deserializeAndValidate(schema, data),
  })
}

export const del = (path: string) => {
  return request({
    method: "DELETE",
    path,
  })
}

export const request = createFetcher(async () => ({
  pathPrefix: "/api/v3",
  baseUrl: (await getConfig()).apiBaseUrl,
  onError: onError,
  headers: await getHeaders(),
}))

const getHeaders = async () => {
  const { apiToken } = await getConfig()
  const { accessToken } = await auth.getActiveSession()
  return {
    accept: "application/vnd.api+json",
    Authorization: "Bearer " + accessToken,
    "API-Token": apiToken,
  }
}

const onError = async (error: FetchError) => {
  if (error.status === 401) {
    await auth.refreshLogin()
  }
}

const deserializeAndValidate = async <T>(schema: Struct<T>, data: unknown) => {
  const deserialized = deserializeJsonApi(data)
  assert(deserialized, schema)
  return deserialized
}
