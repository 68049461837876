import type { v1, v3 } from "backoffice-api"
import type { custom } from "src/bonzai/bonzai"
import { DEVICE_PLATFORM } from "src/constants"

type ProductV1 = v1["getProduct"]
type ProductV3 = v3["getProduct"]["data"]
type ProductType = ProductV3["product_type"]["identifier"]
type ProductStatusV1 = v1["getProductStatus"]["status"]
type ProductProgressV3 = v3["getProductProgress"]["data"]
type ProductStatus = custom["getProductProgress"]

export const getIsInProgress = (
  productType: ProductType,
  status: ProductStatus | undefined
) => {
  if (productType !== "meet") return false

  const hasPlayed = (status?.play_count ?? 0) > 0
  const isCertified = status?.certified ?? false
  return hasPlayed && !isCertified
}

export const getInstallAppLink = (product: ProductV1 | ProductV3) => {
  const { platform } = DEVICE_PLATFORM
  const androidLink = product.google_play_test_link || product.google_play_link
  const iosLink = product.test_flight_link || product.apple_store_link

  if (platform === "Android" && androidLink) return androidLink
  if (platform === "iOS" && iosLink) return iosLink
}

export const getIsAttensiProduct = (productType: ProductType) => {
  const types: ProductType[] = [
    "behavior",
    "process",
    "skill",
    "act",
    "operations",
    "workflow",
  ]
  return types.includes(productType)
}

export const getMaxStarsForProduct = (
  productType: ProductType,
  isScorable: boolean
) => {
  if (!isScorable) return 0

  return getIsAttensiProduct(productType) ? 5 : 1
}

export const getProductLink = (
  productId: number | string,
  productType: ProductType
) => {
  if (getIsAttensiProduct(productType)) {
    return `sim/${productId}`
  }
  if (productType === "activity") {
    return `activity/${productId}`
  }
  if (productType === "meet") {
    return `event/${productId}`
  }
  return `product/${productId}`
}

export const getIsMandatory = (
  status: ProductStatusV1 | ProductProgressV3 | undefined
) => {
  if (!status) return false

  return status.mandatory_of === 1 // What is this? Does this make sense?
}

// Version V1 of this isn't needed anywhere, but it's still
// useful to mark this one as V3 specific
export const getPreferredUploadV3 = (
  product: ProductV3,
  locale: string | undefined
) => {
  const { file_uploads, content_link } = product

  const preferredUpload = file_uploads.find(
    (upload) => upload.locale === locale
  )

  return preferredUpload?.url ?? file_uploads[0]?.url ?? content_link
}

// Version V1 of this isn't needed anywhere, but it's still
// useful to mark this one as V3 specific
export const getIsPlayableV3 = (product: ProductV3) => {
  return product.product_type.open_with === "launcher"
}
