import { record, string, unknown } from "superstruct"
import { exists } from "utils"
import { getLokaliseFile } from "./getLokaliseFile"
import { getManifest, type ProjectManifest } from "./getManifest"

const LocaleFile = record(string(), unknown())

export const loadLokaliseLocales = async (
  locales: string[]
): Promise<Record<string, any>> => {
  const manifest = await getManifest()

  const promises = locales.map((locale) => loadLocale(manifest, locale))
  const loadedLocales = await Promise.all(promises)
  const entries = locales
    .map((locale, index) => [locale, loadedLocales[index]])
    .filter(([_, value]) => exists(value))
  return Object.fromEntries(entries)
}

const loadLocale = async (manifest: ProjectManifest, locale: string) => {
  const fileName = manifest.locales[locale]?.fileName

  if (fileName === undefined) {
    console.warn("Cannot load", locale, ". It is not present in the manifest")
    return
  }

  return getLokaliseFile(LocaleFile, fileName)
}
