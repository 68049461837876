import { never } from "utils"
import { getActiveSession } from "./activeSession"
import { redirectToLogin } from "./redirect"
import { revokeSession } from "./revokeSession"
import { getSessions } from "./storage"

export const logoutAndLogin = async (): Promise<never> => {
  const session = await getActiveSession()
  await revokeSession(session)

  redirectToLogin({
    subdomain: session.subdomain,
    pickCompany: true,
  })

  return never()
}

export const logoutAndGetNext = async () => {
  const oldSession = await getActiveSession()
  await revokeSession(oldSession)

  const sessions = await getSessions()
  const newSession = sessions[0]

  return (
    newSession ??
    redirectToLogin({
      subdomain: oldSession.subdomain,
      pickCompany: true,
    })
  )
}

export const refreshLogin = async () => {
  return redirectToLogin({
    subdomain: (await getActiveSession())?.subdomain,
    pickCompany: true,
  })
}

export const loginNewSession = async () => {
  return redirectToLogin({
    pickCompany: true,
  })
}

export const loginWithLockedSubdomain = async (subdomain: string) => {
  return redirectToLogin({
    subdomain,
    pickCompany: false,
    redirectUrl: window.location.href,
  })
}
