import { createQueryClient } from "bonzai"
import { runFetch } from "fetcher"
import { assert, Struct } from "superstruct"
import { config } from "./config"
import { LOKALISE_URL } from "./constants"

const queryClient = createQueryClient()

export const getLokaliseFile = async <T>(
  schema: Struct<T>,
  fileName: string
) => {
  const { project } = await config
  const path = `/${project}/${fileName}`

  return queryClient.fetchQuery({
    queryKey: [fileName],
    queryFn: () => get(schema, path),
    staleTime: Number.POSITIVE_INFINITY,
    retry: 3,
  })
}

const get = <T>(schema: Struct<T>, path: string) => {
  return runFetch({
    baseUrl: LOKALISE_URL,
    path,
    transform: (data) => validate(schema, data),
  })
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}
