import starFilled from "assets/icons/starFilled.svg"
import userCompletedIcon from "assets/icons/userCompletedIcon.svg"
import userMandatoryIcon from "assets/icons/userMandatoryIcon.svg"
import type { MateriaColor } from "materia"
import { Card, ProgressCircle, Tooltip } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import { toPercent } from "../../../utils/toPercent"
import s from "./UserWidget.module.scss"

/*
  TODO. Implement new design
  https://www.figma.com/file/y4WOhThlwtCQ103l7Va3pt/Incomplete-filter?node-id=207%3A142
*/

export const UserWidget = ({ children }: PropsWithChildren) => (
  <Card borderRadius="24">
    <div className={s.userWidget}>{children}</div>
  </Card>
)

type UserProps = {
  link: FiestaLinkProps
  userDisplayName: string
  position: string
  avatar: string
}
const User = ({ link, avatar, userDisplayName, position }: UserProps) => (
  <FiestaLink className={s.userWidget__user} {...link}>
    <img className={s.userWidget__avatar} src={avatar} alt="" />
    <div className={s.userWidget__userDisplayName}>{userDisplayName}</div>
    <div className={s.userWidget__position}>{position}</div>
  </FiestaLink>
)

const Progress = ({ children }: PropsWithChildren) => (
  <div className={s.progress}>{children}</div>
)

type CompletionProps = {
  progress: number
  progressMax: number
  completedText: string
  variant: "mandatory" | "completed"
}
const Completion = ({
  completedText,
  progress,
  progressMax,
  variant,
}: CompletionProps) => (
  <ProgressContainer
    progress={progress}
    progressText={`${toPercent(progress, progressMax)}%`}
    progressMax={progressMax}
    text={completedText}
    color={variant === "mandatory" ? "purple-40" : "green-30"}
    backgroundColor={variant === "mandatory" ? "pebble" : "green-05"}
    icon={variant === "mandatory" ? userMandatoryIcon : userCompletedIcon}
  />
)

type ProgressTooltipProps = {
  title: string
  description: string
  children: ReactNode
  initiallyIsVisible?: boolean
}
const ProgressTooltip = ({
  title,
  description,
  children,
  initiallyIsVisible,
}: ProgressTooltipProps) => (
  <Tooltip initialIsVisible={initiallyIsVisible}>
    <Tooltip.Trigger>{children}</Tooltip.Trigger>
    <Tooltip.Content>
      <div className={s.progressTooltip}>
        <div className={s.progressTooltip__title}>{title}</div>
        <div className={s.progressTooltip__description}>{description}</div>
      </div>
    </Tooltip.Content>
  </Tooltip>
)

type StarsProps = {
  stars: number
  starsMax: number
  starsText: string
}
const Stars = ({ stars, starsMax, starsText }: StarsProps) => (
  <ProgressContainer
    progress={stars}
    progressMax={starsMax}
    progressText={`${stars}/${starsMax}`}
    text={starsText}
    color="yellow-50"
    backgroundColor="yellow-05"
    icon={starFilled}
  />
)

const Filters = ({ children }: PropsWithChildren) => (
  <div className={s.filters}>{children}</div>
)

type FilterProps = {
  link: FiestaLinkProps
  progress: string
  text: string
  color: "blue" | "pink"
  isActive: boolean
  isVisible: boolean
}
const Filter = ({
  link,
  progress,
  text,
  color,
  isActive,
  isVisible,
}: FilterProps) => {
  if (!isVisible) return null

  return (
    <FiestaLink
      {...link}
      className={modifiers(s, "filter", color, { isActive })}
    >
      <div className={s.filter__progress}>{progress}</div>
      <div className={s.filter__text}>{text}</div>
    </FiestaLink>
  )
}

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__user}>
        <div className={s.skeleton__userAvatar} />
        <div className={s.skeleton__userInfo}>
          <div className={s.skeleton__userInfoName} />
          <div className={s.skeleton__userInfoPosition} />
        </div>
      </div>

      <div className={s.skeleton__progress} />
      <div className={s.skeleton__filters} />
    </div>
  </Card>
)

UserWidget.User = User
UserWidget.Progress = Progress
UserWidget.Completion = Completion
UserWidget.Stars = Stars
UserWidget.ProgressTooltip = ProgressTooltip
UserWidget.Filter = Filter
UserWidget.Filters = Filters
UserWidget.Skeleton = Skeleton

type ProgressContainerProps = {
  progress: number
  progressMax: number
  progressText: string
  text: string
  color: MateriaColor
  backgroundColor: MateriaColor
  icon: string
}
const ProgressContainer = ({
  progress,
  progressMax,
  progressText,
  text,
  icon,
  color,
  backgroundColor,
}: ProgressContainerProps) => (
  <div className={s.progressContainer}>
    <div className={s.progressContainer__radialContainer}>
      <ProgressCircle
        value={progress}
        color={color}
        maxValue={progressMax}
        backgroundColor={backgroundColor}
      />
      <img className={s.progressContainer__radialIcon} src={icon} alt="" />
    </div>
    <div className={s.progressContainer__number}>{progressText}</div>
    <div className={s.progressContainer__description}>{text}</div>
  </div>
)
