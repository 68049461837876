import activityLeaderboardIcon from "assets/icons/activityLeaderboardIcon.svg"
import completedIcon from "assets/icons/completedIcon.svg"
import recentActivityIcon from "assets/icons/recentActivityIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { Card } from "materia"
import type { ReactNode } from "react"
import { modifiers } from "ui-tools"
import s from "./RecentActivityWidget.module.scss"

type RecentActivityWidgetProps = {
  title: string
  children: ReactNode
}
export const RecentActivityWidget = ({
  title,
  children,
}: RecentActivityWidgetProps) => (
  <Card borderRadius="24">
    <div className={s.card}>
      <div className={s.card__top}>
        <img className={s.card__icon} src={recentActivityIcon} alt="" />
        <div className={s.card__title}>{title}</div>
      </div>
      <div className={s.card__entries}>{children}</div>
    </div>
  </Card>
)

type EntryType = "stars" | "completed" | "leaderboard"

type EntryProps = {
  image: string
  name: string
  productTitle: string
  timestamp: string
  summaryText: string
  type: EntryType
}
const Entry = ({
  image,
  name,
  timestamp,
  summaryText,
  productTitle,
  type,
}: EntryProps) => {
  const entryClass = modifiers(s, "entry", {
    orange: type === "leaderboard",
    green: type === "completed",
  })

  const getIcon = () => {
    if (type === "completed") return completedIcon
    if (type === "leaderboard") return activityLeaderboardIcon
    if (type === "stars") return starFilled
  }

  return (
    <div className={entryClass}>
      <img className={s.entry__avatar} src={image} alt="" />
      <div className={s.entry__name}>{name}</div>
      <div className={s.entry__time}>{timestamp}</div>
      <div className={s.entry__summary}>
        <img className={s.entry__icon} src={getIcon()} alt="" />
        <div className={s.entry__summaryText}>{summaryText}</div>
        <div className={s.entry__product}>{productTitle}</div>
      </div>
    </div>
  )
}

const SkeletonEntry = () => (
  <li className={s.skeletonEntry}>
    <div className={s.skeletonEntry__avatar} />
    <div className={s.skeletonEntry__title} />
    <div className={s.skeletonEntry__summary} />
  </li>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__top}>
        <div className={s.skeleton__icon} />
        <div className={s.skeleton__title} />
      </div>
      <ul className={s.skeleton__entries}>
        <SkeletonEntry />
        <SkeletonEntry />
        <SkeletonEntry />
      </ul>
    </div>
  </Card>
)

RecentActivityWidget.Entry = Entry
RecentActivityWidget.Skeleton = Skeleton
