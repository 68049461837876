import { Transition } from "@headlessui/react"
import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import leaderboardColorIcon from "assets/icons/leaderboardColorIcon.svg"
import { Card } from "materia"
import { modifiers } from "ui-tools"

import s from "./CompletedProductBanner.module.scss"

type CompletedProductBannerProps = {
  label: string
  title: string
  variant: "completed" | "points"
  isVisible: boolean
}
export const CompletedProductBanner = ({
  label,
  title,
  variant,
  isVisible,
}: CompletedProductBannerProps) => (
  <Transition
    show={isVisible}
    className={modifiers(s, "banner", variant)}
    enterFrom={s["banner--enterFrom"]}
    enterTo={s["banner--enterTo"]}
    leaveTo={s["banner--leaveTo"]}
    appear
  >
    <Card>
      <div className={s.banner__container}>
        <img className={s.banner__icon} src={getIcon(variant)} alt="" />
        <div className={s.banner__label}>{label}</div>
        <div className={s.banner__title}>{title}</div>
      </div>
    </Card>
  </Transition>
)

const getIcon = (variant: CompletedProductBannerProps["variant"]) => {
  if (variant === "completed") return checkmarkCircleGreen
  if (variant === "points") return leaderboardColorIcon
}
