export const getSubdomainFromStorage = () => {
  try {
    return localStorage.getItem("SUBDOMAIN") ?? undefined
  } catch (error) {
    console.error(error)
  }
}

export const addSubdomainToStorage = (subdomain: string) => {
  try {
    localStorage.setItem("SUBDOMAIN", subdomain)
  } catch (error) {
    console.error(error)
  }
}
