import type { DEFAULT_RESPONSE_TYPE } from "./constants"
import { runFetch, type ResponseType, type RunFetchOptions } from "./runFetch"

type DefaultProps = Omit<
  RunFetchOptions<unknown, typeof DEFAULT_RESPONSE_TYPE>,
  "path" | "transform" | "responseType"
>

export const createFetcher = (
  defaultOptions: DefaultProps | (() => DefaultProps | Promise<DefaultProps>)
) => {
  return async <T, R extends ResponseType>(options: RunFetchOptions<T, R>) => {
    if (typeof defaultOptions === "function") {
      defaultOptions = await defaultOptions()
    }
    return runFetch({ ...defaultOptions, ...options })
  }
}
