import { v1, v3 } from "backoffice-api"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { getNextPageParamV3 } from "src/bonzai/getNextPageParamV3"
import { TEXT_FALLBACK } from "src/constants"
import { getPaginationDataV1 } from "src/dataUtilities/getPaginationDataV1"
import { getPaginationDataV3 } from "src/dataUtilities/getPaginationDataV3"
import { usePickText } from "src/i18n/usePickText"
import { ForYouView, ProductList } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { ProductBundleCardLoader } from "./ProductBundleCardLoader"
import { ProductCardLoader } from "./ProductCardLoader"
import { ProductCardsSkeleton } from "./ProductCardsSkeleton"

type Category = v1["getProductCategories"]["data"][number]

type ProductCategoryProductsLoaderProps = {
  category: Category
}
export const ProductCategoryLoader = (
  props: ProductCategoryProductsLoaderProps
) => (
  <QueryBoundary fallback={<ForYouView.CategorySkeleton />}>
    {/* <Load {...props} /> */}
    <LoadTemp {...props} />
  </QueryBoundary>
)

// Delete this when bundle api is fast enough
const LoadTemp = ({ category }: ProductCategoryProductsLoaderProps) => {
  const { t } = useTranslation()
  const productsData = useDataTemp(category.id)
  const { data, isFetching, fetchNextPage } = productsData
  const { nextPageCount, remainingCount } = getPaginationDataV1(data)
  const hasMoreItems = remainingCount > 0

  const productPages = data.pages.map((page, index) => {
    const isLastPage = index === data.pages.length - 1
    const hideLastCard = hasMoreItems && isLastPage
    const slicedProducts = hideLastCard ? page.data.slice(0, -1) : page.data

    const productCards = slicedProducts.map((product) => (
      <ProductCardLoader
        key={product.id}
        productId={product.id}
        productImage={product.image}
        productTitle={product.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
        productType={product.identifier}
        isScorable={product.scorable}
        apiVersion="v1"
      />
    ))

    return <Fragment key={index}>{productCards}</Fragment>
  })

  const showMoreCount = hasMoreItems ? remainingCount + 1 : remainingCount
  const skeletonCount = hasMoreItems ? nextPageCount + 1 : nextPageCount
  const showMoreText = `${t("product.SHOW_MORE")} (${showMoreCount})`

  return (
    // TODO: use pickText with v3
    <ForYouView.Category title={category.title ?? TEXT_FALLBACK}>
      <ProductList>
        {productPages}
        {isFetching && <ProductCardsSkeleton count={skeletonCount} />}
        {hasMoreItems && !isFetching && (
          <ProductList.ShowMore onClick={fetchNextPage} text={showMoreText} />
        )}
      </ProductList>
    </ForYouView.Category>
  )
}

// Delete this when bundle api is fast enough
const useDataTemp = (category_id: number) => {
  const { i18n } = useTranslation()

  return v1.getProductsInCategory.useInfiniteQuery(
    [{ category_id, locale: i18n.language, per_page: 6 }],
    { getNextPageParam: getNextPageParamV1 }
  )
}

const Load = ({ category }: ProductCategoryProductsLoaderProps) => {
  const { t } = useTranslation()
  const pickText = usePickText()
  const productsData = useData(category.id)

  const { data, isFetching, fetchNextPage } = productsData
  const { nextPageCount, remainingCount } = getPaginationDataV3(data)
  const hasMoreItems = remainingCount > 0

  const productPages = data.pages.map((page, index) => {
    const isLastPage = index === data.pages.length - 1
    const hideLastCard = hasMoreItems && isLastPage
    const slicedProducts = hideLastCard ? page.data.slice(0, -1) : page.data

    const productCards = slicedProducts.map(({ product, product_bundle }) => {
      if (product) {
        return (
          <ProductCardLoader
            key={product.id}
            productId={Number(product.id)}
            productType={product.type.identifier}
            productImage={product.image_url}
            productTitle={pickText(product.title)}
            isScorable={!product.no_score}
            apiVersion="v3"
          />
        )
      } else if (product_bundle) {
        return (
          <ProductBundleCardLoader
            key={product_bundle.id}
            bundleId={product_bundle.id}
            bundleImages={product_bundle.images}
            bundleTitle={pickText(product_bundle.title)}
          />
        )
      } else {
        throw new Error("Product or product bundle is missing from category")
      }
    })

    return <Fragment key={index}>{productCards}</Fragment>
  })

  const showMoreCount = hasMoreItems ? remainingCount + 1 : remainingCount
  const skeletonCount = hasMoreItems ? nextPageCount + 1 : nextPageCount
  const showMoreText = `${t("product.SHOW_MORE")} (${showMoreCount})`

  return (
    // TODO: use pickText with v3
    <ForYouView.Category title={category.title ?? TEXT_FALLBACK}>
      <ProductList>
        {productPages}
        {isFetching && <ProductCardsSkeleton count={skeletonCount} />}
        {hasMoreItems && !isFetching && (
          <ProductList.ShowMore onClick={fetchNextPage} text={showMoreText} />
        )}
      </ProductList>
    </ForYouView.Category>
  )
}

const useData = (category_id: number) => {
  return v3.getContentInCategory.useInfiniteQuery(
    [
      {
        categoryId: category_id,
        "page[size]": 6,
        include: ["product", "product_bundle"],
      },
    ],
    { getNextPageParam: getNextPageParamV3 }
  )
}
