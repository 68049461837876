import { ReactComponent as ArrowDownIcon } from "assets/icons/arrowDown.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import type { PropsWithChildren, ReactNode } from "react"
import s from "./VppModal.module.scss"

export const VppModal = ({ children }: PropsWithChildren) => (
  <div className={s.modal}>{children}</div>
)

type ModalProps = {
  children: ReactNode
  onClose: () => void
}
const Modal = ({ children, onClose }: ModalProps) => (
  <div className={s.modal}>
    <div className={s.modal__dimmer} onClick={onClose} />
    <div className={s.modal__content}>{children}</div>
  </div>
)

type HeaderProps = {
  title: string
  onClose: () => void
}
const Header = ({ title, onClose }: HeaderProps) => (
  <header className={s.header}>
    <div className={s.header__title}>{title}</div>
    <button className={s.header__close} onClick={onClose}>
      <img className={s.header__closeIcon} src={closeIcon} alt="" />
    </button>
  </header>
)

type BodyTextProps = {
  text: string
}
const BodyText = ({ text }: BodyTextProps) => (
  <div className={s.bodyText}>{text}</div>
)

const Countries = ({ children }: PropsWithChildren) => (
  <ul className={s.countries}>{children}</ul>
)

type CountryProps = {
  title: string
  onClick: () => void
}
const Country = ({ title, onClick }: CountryProps) => (
  <li className={s.country}>
    <button className={s.country__button} onClick={onClick}>
      <div className={s.country__title}>{title}</div>
      <ArrowDownIcon className={s.country__icon} />
    </button>
  </li>
)

VppModal.Modal = Modal
VppModal.Header = Header
VppModal.BodyText = BodyText
VppModal.Countries = Countries
VppModal.Country = Country
