import { array, nullable, number, string, type } from "superstruct"

export const MostPlayedProductsResponse = type({
  products: array(
    type({
      product_id: number(),
      playthrough_count: number(),
    })
  ),
})

export const HighScoreEntry = type({
  rank: number(),
  value: number(),
  user: type({
    image: string(),
    username: nullable(string()),
    first_name: nullable(string()),
    last_name: nullable(string()),
  }),
})

export const HighScores = type({
  entries: array(HighScoreEntry),
  my_entry: HighScoreEntry,
})

export const GetLeaderboardRank = type({
  user_id: number(),
  rank: number(),
  total_players: number(),
  high_score: number(),
})
