import { useStableCallback } from "hooks"
import { useEffect } from "react"
import type { z } from "zod"
import { getVuplex } from "./getVuplex"
import { IncomingMessage } from "./IncomingMessage"

type Message = z.infer<typeof IncomingMessage>
type MessageType = Message["type"]
type ExtractedMessage<T extends MessageType> = Extract<Message, { type: T }>
type Event = { data: string }

export const subscribeToVuplex = <T extends MessageType>(
  messageType: T,
  handler: (message: ExtractedMessage<T>) => void
) => {
  // Use promise to be able return the unsubscribe callback immediately
  const vuplexPromise = getVuplex()

  const onMessage = (event: Event) => {
    const data = parseIncomingMessage(event.data)

    if (data.type === messageType) {
      handler(data as ExtractedMessage<T>)
    }
  }

  vuplexPromise.then((vuplex) => {
    vuplex.addEventListener("message", onMessage)
  })

  return () => {
    vuplexPromise.then((vuplex) => {
      vuplex.removeEventListener("message", onMessage)
    })
  }
}

export const useSubscribeToVuplex = <T extends MessageType>(
  messageType: T,
  handler: (message: ExtractedMessage<T>) => void
) => {
  const stableHandler = useStableCallback(handler)

  useEffect(() => {
    const unsubscribe = subscribeToVuplex(messageType, stableHandler)
    return unsubscribe
  }, [messageType, stableHandler])
}

const parseIncomingMessage = (data: string) => {
  const message = JSON.parse(data)
  return IncomingMessage.parse(message)
}
