import chevronIcon from "assets/icons/chevronIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { Card, ProgressBar } from "materia"
import type { PropsWithChildren } from "react"
import { FiestaLink, type FiestaLinkProps } from "utility-components"
import s from "./ProductBundleView.module.scss"

export const ProductBundleView = ({ children }: PropsWithChildren) => (
  <div className={s.productBundleView}>{children}</div>
)

type BackProps = {
  text: string
  link: FiestaLinkProps
}
const Back = ({ text, link }: BackProps) => (
  <Card borderRadius="12">
    <FiestaLink className={s.back} {...link}>
      {text}
    </FiestaLink>
  </Card>
)

type HeaderProps = {
  title: string
  progress: number
  progressMax: number
  stars: number
  starsMax: number
  backLink: FiestaLinkProps
}
const Header = ({
  title,
  progress,
  progressMax,
  stars,
  starsMax,
  backLink,
}: HeaderProps) => (
  <div className={s.header}>
    <div className={s.header__centered}>
      <div className={s.header__widgetLayoutSize}>
        <FiestaLink className={s.header__back} {...backLink}>
          <img className={s.header__backIcon} src={chevronIcon} alt="" />
        </FiestaLink>
        <div className={s.header__title}>{title}</div>
        <div className={s.header__progress}>
          <ProgressBar
            value={progress}
            maxValue={progressMax}
            color="purple-60"
            backgroundColor="purple-10"
          />
        </div>
        <div className={s.header__stars}>
          <img className={s.header__star} src={starFilled} alt="" />
          {stars}/{starsMax}
        </div>
      </div>
    </div>
  </div>
)

const HeaderSkeleton = () => {
  return (
    <div className={s.headerSkeleton}>
      <div className={s.headerSkeleton__centered}>
        <div className={s.headerSkeleton__widgetLayoutSize}>
          <div className={s.headerSkeleton__back}></div>
          <div className={s.headerSkeleton__title} />
          <div className={s.headerSkeleton__stars} />
        </div>
      </div>
    </div>
  )
}

ProductBundleView.Back = Back
ProductBundleView.Header = Header
ProductBundleView.HeaderSkeleton = HeaderSkeleton
