import type { Params, Route } from "@tanstack/react-location"
import { Navigate, Outlet } from "@tanstack/react-location"
import { WidgetLayoutLoader } from "src/components/WidgetLayoutLoader"
import { ExploreViewLoader } from "./components/ExploreViewLoader"
import { ForYouViewLoader } from "./components/ForYouViewLoader"
import { FrontPageLoader } from "./components/FrontPageLoader"
import { JourneyViewLoader } from "./components/JourneyViewLoader"
import { KpiSalesModalLoader } from "./components/KpiSalesModalLoader"
import { LeaderboardViewResponsive } from "./components/LeaderboardViewResponsive"
import { NewsItemViewLoader } from "./components/NewsItemViewLoader"
import { NewsViewLoader } from "./components/NewsViewLoader"
import { PdfModalLoader } from "./components/PdfModalLoader"
import {
  ProductBundleViewLoader,
  ProductBundleViewLoaderHeader,
} from "./components/ProductBundleViewLoader"
import { ProductModalShellLoader } from "./components/ProductModalShellLoader"
import { RedirectView } from "./components/RedirectView"
import { UserSettingsViewLoader } from "./components/UserSettingsViewLoader"
import { VideoModalLoader } from "./components/VideoModalLoader"
import { VppModalLoader } from "./components/VppModalLoader"
import { WebGLModalLoader } from "./components/WebGLModalLoader"
import { WebGamesModalLoader } from "./components/WebGamesModalLoader"

export const getRoutes = (): Route[] => [
  {
    path: "/",
    element: <FrontPageLoader />,
  },

  {
    path: "/for-you",
    element: (
      <WidgetLayoutLoader>
        <ForYouViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/for-you"),
  },

  {
    path: "/journey",
    element: (
      <WidgetLayoutLoader>
        <JourneyViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/journey"),
  },

  {
    path: "/explore",
    element: (
      <WidgetLayoutLoader>
        <ExploreViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/explore"),
  },

  {
    path: "/leaderboards",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <LeaderboardViewResponsive />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/leaderboards"),
  },

  {
    path: "/news/post/:newsPostId",
    element: <NewsItemViewLoader />,
  },

  {
    path: "/news",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <NewsViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes(() => "/news"),
  },

  {
    path: "/me",
    element: <UserSettingsViewLoader />,
  },

  {
    path: "/bundle/:bundleId",
    element: (
      <>
        <ProductBundleViewLoaderHeader />
        <WidgetLayoutLoader showUserInfoHeader={false}>
          <ProductBundleViewLoader />
          <Outlet />
        </WidgetLayoutLoader>
      </>
    ),
    children: getChildRoutes((params) => `/bundle/${params.bundleId}`),
  },

  {
    path: "/redirect",
    element: <RedirectView />,
  },

  {
    element: <Navigate to="/" />,
  },
]

const getChildRoutes = (
  getCloseRoute: (params: Params<string>) => string
): Route[] => [
  {
    path: "/product/:productId/video",
    element: async ({ params }) => (
      <VideoModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/product/:productId/pdf",
    element: async ({ params }) => (
      <PdfModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/product/:productId/web-games",
    element: async ({ params }) => (
      <WebGamesModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/product/:productId",
    element: async ({ params }) => (
      <ProductModalShellLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/sim/:productId/play",
    element: async ({ params }) => (
      <WebGLModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },

  {
    path: "/sim/:productId",
    element: async ({ params }) => (
      <>
        <ProductModalShellLoader
          closeRoute={getCloseRoute(params)}
          sideContent="leaderboard"
        />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "vpp",
        element: async ({ params }) => (
          <VppModalLoader
            closeRoute={`${getCloseRoute(params)}/sim/${params.productId}`}
          />
        ),
      },
    ],
  },

  {
    path: "/event/:productId",
    element: async ({ params }) => (
      <ProductModalShellLoader
        closeRoute={getCloseRoute(params)}
        sideContent="event"
      />
    ),
  },

  {
    path: "/activity/:productId",
    element: async ({ params }) => (
      <ProductModalShellLoader
        closeRoute={getCloseRoute(params)}
        sideContent="activity"
      />
    ),
  },

  {
    path: "/kpi-sales",
    element: async ({ params }) => (
      <KpiSalesModalLoader closeRoute={getCloseRoute(params)} />
    ),
  },
]
