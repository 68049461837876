import { v1, v3 } from "backoffice-api"

type EventDate = v3["getProductCourse"]["data"]

export const toggleAttending = async (event: EventDate) => {
  optimisticUpdate(event)
  await apiUpdate(event)
}

const optimisticUpdate = ({ id }: EventDate) => {
  v3.getProduct.setQueriesData((product) => {
    const event = product.data.courses?.find((event) => event.id === id)
    if (!event) return

    const { is_attending, attendees_count } = event

    event.is_attending = !is_attending
    event.attendees_count = is_attending
      ? attendees_count - 1
      : attendees_count + 1
  })
}

const apiUpdate = async (event: EventDate) => {
  return event.is_attending
    ? v1.deleteEventAttending(Number(event.id))
    : v1.setEventAttending(Number(event.id))
}
