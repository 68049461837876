import { never, withQueryParams, type QueryParams } from "utils"
import { getActiveSession } from "./activeSession"
import { getConfig } from "./config"
import { accessTokenToTransferKey } from "./transferKey"

type RedirectToLogin = {
  subdomain?: string
  redirectUrl?: string
  pickCompany: boolean
}

export const redirectToLogin = async ({
  subdomain,
  pickCompany,
  redirectUrl = window.location.origin,
}: RedirectToLogin) => {
  const { loginUrl } = await getConfig()
  return redirectTo(loginUrl, {
    subdomain: subdomain,
    redirect: redirectUrl,
    pick_company: pickCompany,
    transfer_key: "true",
    reset: "true",
  })
}

type RedirectWithActiveSession = {
  url: string
}
export const redirectWithActiveSession = async ({
  url,
}: RedirectWithActiveSession) => {
  const { subdomain, accessToken, companyNameKey } = await getActiveSession()
  const transfer_key = await accessTokenToTransferKey(accessToken)
  return redirectTo(url, {
    subdomain,
    transfer_key,
    company_name_key: companyNameKey,
  })
}

const redirectTo = async (url: string, params: QueryParams) => {
  const href = withQueryParams(url, params)
  window.location.replace(href)
  return never()
}
