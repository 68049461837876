export const compareVersions = (
  baseVersion: string,
  compareVersion: string
) => {
  const left = splitTextIntoSegments(baseVersion)
  const right = splitTextIntoSegments(compareVersion)

  if (left.length !== right.length) {
    throw new Error(
      `Versions aren't of the same format ${baseVersion}, ${compareVersion}`
    )
  }
  return compareSegments(left, right)
}

const compareSegments = (left: number[], right: number[]) => {
  for (const [index, leftItem] of left.entries()) {
    const rightItem = right[index]!
    if (leftItem > rightItem) return -1
    if (rightItem > leftItem) return 1
  }
  return 0
}

const splitTextIntoSegments = (text: string) => {
  return text
    .split(".")
    .map((value) => Number(value))
    .filter((value) => !Number.isNaN(value))
}
