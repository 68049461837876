import { mapRelationships } from "../mapRelationships"
import { transformKeys } from "../transfromKeys"

export const deserialize = (resp: any, options = {}) => {
  const { data = {}, included, ...rest } = resp
  let deserialized

  if (Array.isArray(data)) {
    deserialized = data.map((resource) => mapRelationships(resource, included))
  } else {
    deserialized = mapRelationships(data, included)
  }

  transformKeys(deserialized, options)

  return { data: deserialized, ...rest }
}
