import { runFetch } from "fetcher"
import { getConfig } from "./config"

export const revokeAccessToken = async (session: { accessToken: string }) => {
  const { apiToken, backofficeUrl } = await getConfig()

  await runFetch({
    baseUrl: backofficeUrl,
    path: `/api/v1/oauth/revoke`,
    method: "POST",
    headers: {
      "API-Token": apiToken,
      Authorization: `Bearer ${session.accessToken}`,
    },
    data: {
      token: session.accessToken,
      token_type_hint: "access_token",
    },
  })
}
