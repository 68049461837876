import { camelCase, isArray, isPlainObject } from "lodash-es"

const needsCamelCase = (str: string) => {
  return str.indexOf("-") > -1 || str.indexOf("_") > -1
}

export const toCamel = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const camelCasedKey = needsCamelCase(key) ? camelCase(key) : key
    const value = obj[key]
    delete obj[key]
    obj[camelCasedKey] = value

    if (isPlainObject(value)) {
      obj[camelCasedKey] = toCamel(value)
    }

    if (isArray(value)) {
      obj[camelCasedKey] = value.map((item: any) => {
        if (isPlainObject(item)) {
          return toCamel(item)
        } else {
          return item
        }
      })
    }
  })

  return obj
}
