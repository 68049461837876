import { auth } from "auth"
import { createFetcher, type FetchError, type QueryParams } from "fetcher"
import { assert, Struct } from "superstruct"
import { getConfig } from "../apiConfig"

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return request({
    method: "GET",
    path,
    params,
    transform: (data) => validate(schema, data),
  })
}

export const post = <T>(schema: Struct<T>, path: string, data: any) => {
  return request({
    method: "POST",
    path,
    data,
    transform: (data) => validate(schema, data),
  })
}

// Jank from the api. It sometimes returns data on post, sometimes not.
export const postNoResponse = (path: string, data: any) => {
  return request({
    method: "POST",
    path,
    data,
  })
}

export const patch = <T>(schema: Struct<T>, path: string, data: any) => {
  return request({
    method: "PATCH",
    path,
    data,
    transform: (data) => validate(schema, data),
  })
}

export const del = <T>(schema: Struct<T>, path: string, data: any) => {
  return request({
    method: "DELETE",
    path,
    data,
    transform: (data) => validate(schema, data),
  })
}

export const request = createFetcher(async () => ({
  pathPrefix: "/api/v1",
  headers: await getHeaders(),
  baseUrl: (await getConfig()).apiBaseUrl,
  onError,
}))

const getHeaders = async () => {
  const { apiToken } = await getConfig()
  const { accessToken } = await auth.getActiveSession()
  return {
    Authorization: "Bearer " + accessToken,
    "API-Token": apiToken,
  }
}

const onError = async (error: FetchError) => {
  if (error.status === 401) {
    await auth.refreshLogin()
  }
}

const validate = async <T>(schema: Struct<T>, data: unknown) => {
  assert(data, schema)
  return data
}
