import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import lightningIcon from "assets/icons/lightningIcon.svg"
import productInProgress from "assets/icons/productInProgress.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { Card } from "materia"
import type { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import {
  FiestaImage,
  FiestaLink,
  type FiestaLinkProps,
} from "utility-components"
import s from "./ProductCard.module.scss"

type Props = {
  image: string
  link: FiestaLinkProps
  productType: string
  stars: number
  starsMax: number
  title: string

  isCertified: boolean
  isFastProduct: boolean
  isInProgress: boolean
  isMandatory: boolean
  isPlacementVisible: boolean
  isProductTypeVisible: boolean

  inProgressText: string
  mandatoryText: string
  pointsText: string
  rankText: string
}

export const ProductCard = (props: Props) => {
  const {
    isMandatory,
    isCertified,
    link,
    isInProgress,
    isPlacementVisible,
    isProductTypeVisible,
  } = props

  return (
    <Card>
      <FiestaLink {...link} className={s.productCard}>
        {isMandatory && <Mandatory {...props} />}
        {isCertified && <Certified />}
        {isInProgress && <InProgress />}
        <Image {...props} />
        <Bottom>
          {isPlacementVisible && <Placement {...props} />}
          {isProductTypeVisible && <ProductType {...props} />}
          <Title {...props} />
          <Stars {...props} />
        </Bottom>
      </FiestaLink>
    </Card>
  )
}

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const InProgress = () => (
  <img className={s.inProgress} src={productInProgress} alt="" />
)

const Image = ({ image }: Props) => (
  <FiestaImage
    className={s.image}
    src={image}
    alt=""
    loading="lazy"
    srcSet={[100, 200, 300, 600, 900]}
    sizes="(width = small) 100px, (width = medium) 33vw, 200px"
  />
)

const Bottom = ({ children }: PropsWithChildren) => (
  <div className={s.bottom}>{children}</div>
)

const Placement = ({ pointsText, rankText }: Props) => (
  <div className={s.placement}>
    <div className={s.placement__points}>{pointsText}</div>
    <div className={s.placement__rank}>{rankText}</div>
  </div>
)

const ProductType = ({
  productType,
  isInProgress,
  inProgressText,
  isFastProduct,
}: Props) => (
  <div className={modifiers(s, "productType", { isFastProduct })}>
    {isFastProduct && (
      <img className={s.productType__icon} src={lightningIcon} alt="" />
    )}
    {productType}
    {isInProgress && ` (${inProgressText})`}
  </div>
)

const Title = ({ title }: Props) => <div className={s.title}>{title}</div>

const Stars = ({ starsMax, stars }: Props) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}

/**
 * Shell scales exactly the same as ProductCard.
 * Use this component for other cards so they're all the same size.
 */
const Shell = ({ children }: PropsWithChildren) => (
  <Card>
    <div className={s.shell}>
      <div className={s.shell__background}>
        <div className={s.shell__image} />
        <div className={s.shell__bottom}>
          <div className={s.shell__type}>&nbsp;</div>
          <div className={s.shell__title}>&nbsp;</div>
          <div className={s.shell__stars} />
        </div>
      </div>
      <div className={s.shell__content}>{children}</div>
    </div>
  </Card>
)

const Skeleton = () => (
  <Shell>
    <div className={s.skeleton}>
      <div className={s.skeleton__image} />
      <div className={s.skeleton__bottom}>
        <div className={s.skeleton__bottomType} />
        <div className={s.skeleton__bottomTitle} />
      </div>
    </div>
  </Shell>
)

ProductCard.Skeleton = Skeleton
ProductCard.Shell = Shell
