import kpiIcon from "assets/icons/kpiIcon.svg"
import { Card } from "materia"
import type { PropsWithChildren } from "react"
import { toPercent } from "../../../utils/toPercent"

import { ReactComponent as ChevronIcon } from "assets/icons/chevronIcon.svg"
import { modifiers } from "ui-tools"
import s from "./KpiSalesWidget.module.scss"

export const KpiSalesWidget = ({ children }: PropsWithChildren) => (
  <Card borderRadius="24">
    <div className={s.widget}>{children}</div>
  </Card>
)

const Header = ({ children }: PropsWithChildren) => (
  <div className={s.header}>{children}</div>
)

type TitleProps = {
  text: string
}
const Title = ({ text }: TitleProps) => (
  <div className={s.headerTitle}>
    <img className={s.headerTitle__icon} src={kpiIcon} alt="" />
    <div className={s.headerTitle__text}>{text}</div>
  </div>
)

const Chart = ({ children }: PropsWithChildren) => (
  <div className={s.chart}>{children}</div>
)

type PaginationArrowProps = {
  direction: "left" | "right"
  isDisabled: boolean
  onClick: () => void
}
const PaginationArrow = ({
  direction,
  isDisabled,
  onClick,
}: PaginationArrowProps) => (
  <button
    className={modifiers(s, "paginationArrow", direction)}
    disabled={isDisabled}
    title={`Arrow ${direction}`}
    onClick={onClick}
  >
    <ChevronIcon className={s.paginationArrow__icon} />
  </button>
)

const Entries = ({ children }: PropsWithChildren) => (
  <div className={s.entries}>{children}</div>
)

type EntryProps = {
  value: number
  maxValue: number
  month: string
}
const Entry = ({ value, maxValue = 100, month }: EntryProps) => (
  <div className={s.entry}>
    <div className={s.entry__value}>{value}</div>
    <div
      className={s.entry__bar}
      style={{ height: `${toPercent(value, maxValue === 0 ? 1 : maxValue)}px` }}
    />
    <div className={s.entry__month}>{month}</div>
  </div>
)

const Summary = ({ children }: PropsWithChildren) => (
  <div className={s.summary}>{children}</div>
)

type SummaryItemProps = {
  value: number
  label: string
}
const SummaryItem = ({ value, label }: SummaryItemProps) => (
  <div className={s.summaryItem}>
    <div className={s.summaryItem__value}>{value}</div>
    <div className={s.summaryItem__label}>{label}</div>
  </div>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton} />
  </Card>
)

KpiSalesWidget.Header = Header
KpiSalesWidget.Title = Title
KpiSalesWidget.Chart = Chart
KpiSalesWidget.PaginationArrow = PaginationArrow
KpiSalesWidget.Entries = Entries
KpiSalesWidget.Entry = Entry
KpiSalesWidget.Summary = Summary
KpiSalesWidget.SummaryItem = SummaryItem
KpiSalesWidget.Skeleton = Skeleton
