import * as http from "./http"
import type { GetHighScoresParams, GetMostPlayedProductsParams } from "./params"
import {
  GetLeaderboardRank,
  HighScores,
  MostPlayedProductsResponse,
} from "./responses"

export const getMostPlayedProducts = (params: GetMostPlayedProductsParams) => {
  const url = `/leaderboards/most_played_product`
  return http.get(MostPlayedProductsResponse, url, params)
}

export const getHighScores = (params: GetHighScoresParams) => {
  return http.get(HighScores, `/frontend/leaderboards/high_score`, params)
}

export const getRank = (product_id: number) => {
  return http.get(GetLeaderboardRank, `me/leaderboards/rank/${product_id}`)
}
