import { createResolvable } from "utils"

export type ApiConfig = {
  leaderboardApiUrl: string
  apiToken: string
}

export const { promise, resolve } = createResolvable<ApiConfig>()

export const initializeLeaderboardApi = (config: ApiConfig) => {
  resolve(config)
}

export const getConfig = () => {
  return promise
}
