import searchIcon from "assets/icons/searchIcon.svg"
import {
  forwardRef,
  type InputHTMLAttributes,
  type PropsWithChildren,
} from "react"
import { modifiers } from "ui-tools"
import s from "./ExploreView.module.scss"

export const ExploreView = ({ children }: PropsWithChildren) => <>{children}</>

const Filters = ({ children }: PropsWithChildren) => (
  <div className={s.filters}>{children}</div>
)

type FilterProps = {
  isActive: boolean
  onClick: () => void
  text: string
}
const Filter = ({ isActive, onClick, text }: FilterProps) => (
  <button className={modifiers(s, "filter", { isActive })} onClick={onClick}>
    {text}
  </button>
)

const FilterSkeleton = () => (
  <button className={s.filterSkeleton}>&nbsp;</button>
)

type SearchProps = InputHTMLAttributes<HTMLInputElement>
const Search = forwardRef<HTMLInputElement, SearchProps>((props, ref) => (
  <div className={s.search}>
    <img className={s.search__icon} src={searchIcon} alt="" />
    <input
      ref={ref}
      className={s.search__input}
      aria-label="Search title or description"
      {...props}
    />
  </div>
))

ExploreView.Filters = Filters
ExploreView.Filter = Filter
ExploreView.FilterSkeleton = FilterSkeleton
ExploreView.Search = Search
