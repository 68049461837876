import { unknown } from "superstruct"
import * as http from "./v1Http"
import type {
  CreateProductParams,
  CreateProductPolicyParams,
  GetEventDatesAdminParams,
  GetMandatoryProductsParams,
  GetProductCategoriesParams,
  GetProductsInCategoryParams,
  GetProductsInExploreParams,
  GetProductsSearchParams,
  GetUsersSearchParams,
  UpdateProductParams,
  UpdateUserParams,
} from "./v1Params"
import {
  ActivityFeedResponse,
  AvatarsResponse,
  Company,
  CreateProductResponse,
  DepartmentsResponse,
  EventDatesResponse,
  ExploreProductTypesResponse,
  GetProductCoursesAdminResponse,
  GetUsersSearchResponse,
  MandatoryProductsResponse,
  PlayableProductIds,
  PoliciesResponse,
  Product,
  ProductCategoriesResponse,
  ProductPlatformDetails,
  ProductPoliciesResponse,
  ProductsInCategoryResponse,
  ProductsSearchResponse,
  StatusResponse,
  ToggleAttendingResponse,
  User,
  UserNameIsAvailableResponse,
  UserStatus,
  VppCodeResponse,
  VppCountriesResponse,
} from "./v1Responses"

export const getCurrentCompany = () => {
  return http.get(Company, `/companies/current`)
}

export const getProduct = (productId: number) => {
  return http.get(Product, `/products/${productId}`)
}

export const updateProduct = (productId: number, data: UpdateProductParams) => {
  return http.patch(Product, `/products/${productId}`, data)
}

export const createProduct = (data: CreateProductParams) => {
  return http.post(CreateProductResponse, `/products`, data)
}

export const getProductStatus = (productId: number) => {
  return http.get(StatusResponse, `/products/${productId}/status`)
}

export const getMe = () => {
  return http.get(User, `/users/me`)
}

export const updateUser = (user: UpdateUserParams) => {
  return http.patch(User, `/users/me`, { user })
}

export const getIsUsernameAvailable = (userName: string) => {
  return http.get(
    UserNameIsAvailableResponse,
    `users/usernameavailable/${userName}`
  )
}

export const getAvatars = () => {
  return http.get(AvatarsResponse, `/avatars`)
}

export const getUserStatus = () => {
  return http.get(UserStatus, `/main/status`)
}

export const getActivityFeed = () => {
  return http.get(ActivityFeedResponse, `/frontend/activity-feed`)
}

export const getProductCategories = (params: GetProductCategoriesParams) => {
  const path = `/frontend/me/product-categories`
  return http.get(ProductCategoriesResponse, path, params)
}

export const getProductsInCategory = (params: GetProductsInCategoryParams) => {
  return http.get(ProductsInCategoryResponse, `/frontend/me/products`, params)
}

export const getProductsInExplore = (params: GetProductsInExploreParams) => {
  return http.get(
    ProductsInCategoryResponse,
    "frontend/me/products/explore",
    params
  )
}

export const getProductPolicies = (productId: number) => {
  return http.get(ProductPoliciesResponse, `products/${productId}/policies`)
}

export const getPolicies = () => {
  return http.get(PoliciesResponse, "policies")
}

export const getExploreProductTypes = () => {
  const url = "frontend/me/products/explore/identifiers"
  return http.get(ExploreProductTypesResponse, url)
}

export const getMandatoryProducts = (params: GetMandatoryProductsParams) => {
  const path = `/frontend/me/products/mandatory`
  return http.get(MandatoryProductsResponse, path, params)
}

export const getProductPlatformDetails = (
  productId: number,
  platform: string
) => {
  const path = `/frontend/me/products/${productId}`
  return http.get(ProductPlatformDetails, path, { platform })
}

export const getPlayableProductIds = () => {
  return http.get(
    PlayableProductIds,
    "/frontend/me/products/playable_product_ids"
  )
}

export const getDepartmentsBranch = () => {
  return http.get(DepartmentsResponse, "/users/me/departments/branch")
}

export const getEventDates = (productId: number) => {
  const url = `frontend/me/products/${productId}/courses`
  return http.get(EventDatesResponse, url)
}

export const setEventAttending = (eventId: number) => {
  const url = `frontend/me/courses/${eventId}/status`
  return http.post(ToggleAttendingResponse, url, null)
}

export const deleteEventAttending = (eventId: number) => {
  const url = `frontend/me/courses/${eventId}/status`
  return http.del(ToggleAttendingResponse, url, null)
}

export const createPlaythrough = (product_id: number) => {
  return http.post(unknown(), `/frontend/me/products/${product_id}/open`, {})
}

export const getVppCountries = (productId: number) => {
  const url = `products/${productId}/redeem/available_stores`
  return http.get(VppCountriesResponse, url)
}

export const getVppCode = (productId: number, store: string) => {
  const url = `products/${productId}/redeem/${store}`
  return http.get(VppCodeResponse, url)
}

export const getProductsSearch = (params: GetProductsSearchParams) => {
  return http.get(ProductsSearchResponse, `products/search`, params)
}

export const createProductPolicy = (
  policyId: number,
  params: CreateProductPolicyParams
) => {
  const body = { policy_product: params }
  return http.postNoResponse(`policies/${policyId}/products`, body)
}

export const getProductCoursesAdmin = (
  productId: number,
  params: GetEventDatesAdminParams
) => {
  const state = params.state.join(",")
  const url = `/products/${productId}/courses`
  return http.get(GetProductCoursesAdminResponse, url, { ...params, state })
}

export const getUsersSearch = (params: GetUsersSearchParams) => {
  return http.get(GetUsersSearchResponse, `/users/search`, params)
}
