import { getEnv as _getEnv, type ServerConfig } from "@attensi/frontend-server"
import { showErrorPopup } from "error-popup"

export const getEnv = <Config extends ServerConfig>(config: Config) => {
  return _getEnv({
    config,
    getLocalEnv: getViteEnv,
    onMissingKeys: handleErrors,
  })
}

const getViteEnv = (key: string) => {
  // This needs to be split up into two lines.
  // Combining them into import.meta.env[key] will crash the build for some reason
  // Likely a bug in vite
  const env = import.meta.env
  return env[key]
}

const handleErrors = (keys: string[]) => {
  let errorToThrow: Error | undefined

  for (const key of keys) {
    const error = new Error(`${key} is missing from environment`)
    showErrorPopup({ error, skipLandingPage: true })
    errorToThrow = error
  }

  if (errorToThrow) throw errorToThrow
}
