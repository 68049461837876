import { createResolvable } from "utils"

export type AuthConfig = {
  authUrl: string
  apiToken: string
  backofficeUrl: string
  loginUrl: string
  pickedLocale: string | undefined
  subdomain: string | undefined
  transferKey: string | undefined
  rememberMe: boolean
  impersonation: boolean
  environment: string
}

const { promise, resolve } = createResolvable<AuthConfig>()

export const getConfig = () => promise
export const setConfig = resolve
