import type { v1 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { leaderboard } from "leaderboard-api"
import { useTranslation } from "react-i18next"
import { custom } from "src/bonzai/bonzai"
import { formatNumber } from "src/dataUtilities/formatNumber"
import { useGetOrdinal } from "src/dataUtilities/getOrdinal"
import {
  getIsAttensiProduct,
  getIsInProgress,
  getMaxStarsForProduct,
  getProductLink,
} from "src/dataUtilities/productDataUtilities"
import { ProductCard } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { ProductCardActivityLoader } from "./ProductCardActivityLoader"
import { ProductCardEventLoader } from "./ProductCardEventLoader"

type ProductType = v1["getProduct"]["product_type"]
type ApiVersion = "v1" | "v3"

type ProductCardLoaderProps = {
  productId: number
  productType: ProductType
  productImage: string
  productTitle: string
  isScorable: boolean
  apiVersion: ApiVersion
}
export const ProductCardLoader = (props: ProductCardLoaderProps) => (
  <QueryBoundary fallback={<ProductCard.Skeleton />}>
    <Switcher {...props} />
  </QueryBoundary>
)

const Switcher = (props: ProductCardLoaderProps) => {
  if (props.productType === "activity") {
    return <ProductCardActivityLoader {...props} />
  }

  if (props.productType === "meet") {
    return <ProductCardEventLoader {...props} />
  }

  return <DefaultCardLoader {...props} />
}

const DefaultCardLoader = ({
  productId,
  productType,
  productTitle,
  productImage,
  isScorable,
  apiVersion,
}: ProductCardLoaderProps) => {
  const { t, i18n } = useTranslation()
  const getOrdinal = useGetOrdinal()

  const [status, rankResponse] = useData(productId, apiVersion)

  const hasScore = (status?.high_score ?? 0) > 0
  const rank = hasScore ? rankResponse?.rank : undefined
  const rankText = rank ? `${getOrdinal(rank)} ${t("product.RANK_UNIT")}` : ""

  const scoreFormatted = formatNumber(i18n.language, status?.high_score ?? 0)
  const pointsText = `${scoreFormatted} ${t("product.SCORE")}`

  const productTypeText = t(`product.PRODUCT_TYPES.${productType}`)

  const isCertified = status?.certified ?? false
  const isInProgress = getIsInProgress(productType, status)
  const isAttensiProduct = getIsAttensiProduct(productType)
  const isMandatory = status?.is_mandatory ?? false
  const stars = status?.stars ?? 0
  const maxStars = getMaxStarsForProduct(productType, isScorable)

  return (
    <ProductCard
      image={productImage}
      link={{ to: getProductLink(productId, productType) }}
      productType={productTypeText}
      stars={stars}
      starsMax={maxStars}
      title={productTitle}
      isCertified={isCertified}
      isFastProduct={productType === "fast"}
      isInProgress={isInProgress}
      isMandatory={isMandatory}
      isPlacementVisible={isAttensiProduct}
      isProductTypeVisible={!isAttensiProduct}
      inProgressText={t("product.PRODUCT_IN_PROGRESS")}
      mandatoryText={t("user.MANDATORY")}
      pointsText={pointsText}
      rankText={rankText}
    />
  )
}

const useData = (productId: number, apiVersion: ApiVersion) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion }], {
      suspense: false,
    }),

    leaderboard.getRank.useQuery([productId], {
      suspense: false,
    }),
  ])
}
